import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Host } from '../../Data';
import { allusersLoadingSuccess } from "../../Redux/Allusers_slice";
import { deleteUserSuccess, updateUserSuccess } from "../../Redux/Allusers_slice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AllUsers() {

    const Admin = useSelector(state => state.Admin.Admin);
    const dispatch = useDispatch();
    const allusers = useSelector(state => state.Allusers.Allusers);
    const [isUpdateModeOpen, setIsUpdateModeOpen] = useState(false);
    const [currentUserID, setCurrentUserID] = useState("");
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [passwordUpdateMode, setPasswordUpdateMode] = useState(false)



    const toggleUpdateMode = (user = null) => {
        if (user) {
            setCurrentUserID(user._id);
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmail(user.email);
            setPhone(user.phone);
            setWhatsappNumber(user.whatsappNumber);
        }
        setIsUpdateModeOpen(!isUpdateModeOpen)
    }

    const passwordUpdate = async (e, userId) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            return toast.error("Password do not match")
        }
        const passwordChange = {
            adminPassword: adminPassword,
            userNewPassword: password
        }

        try {
            await axios.put(`${Host}/api/admins/users/password/${userId}`, passwordChange, {
                headers: {
                    "Authorization": `Bearer ${Admin.token}`
                }
            });
            setPassword('');
            setConfirmPassword("")
            setCurrentUserID("")
            setAdminPassword("")
            toast.success('User password updated successfully');
        } catch (err) {
            toast.error(err.response.data.error)
        }
    }

    const UpdateUserData = async (userId) => {
        let adminPassword = window.prompt("Give Admin Password: ")
        if (!adminPassword) {
            return
        }
        const userData = {
            firstName,
            lastName,
            email,
            whatsappNumber,
            phone
        }
        try {
            const response = await axios.put(`${Host}/api/admins/users/${userId}`, userData, {
                headers: {
                    "Authorization": `Bearer ${Admin.token}`
                }
            });
            dispatch(updateUserSuccess(response.data.data))
            toast.success('User data updated successfully');
            setCurrentUserID("")
        } catch (err) {
            toast.error(err.response.data.error)
        }
    }


    const DeleteUser = async (userId) => {
        let adminPassword = window.prompt("Give Admin Password: ")
        if (!adminPassword) {
            return
        }
        try {
            await axios.delete(`${Host}/api/admins/users/${userId}`, {
                headers: {
                    "Authorization": `Bearer ${Admin.token}`
                },
                data: {
                    adminPassword
                }
            });
            dispatch(deleteUserSuccess(userId));
            toast.success("Delete Success")
        } catch (error) {
            toast.error(error.response.data.error)
        }
    }

    const togglePasswordUpdateMode = (id) => {
        setCurrentUserID(id)
        setPasswordUpdateMode(!passwordUpdateMode)
    }

    useEffect(() => {
        const get_all_users = async () => {
            try {
                const response = await axios.get(`${Host}/api/admins/users`, {
                    headers: {
                        'Authorization': `Bearer ${Admin.token}`
                    }
                });
                dispatch(allusersLoadingSuccess(response.data.data));
            } catch (err) {
                console.log(err)
            }
        }
        get_all_users();
    }, [Admin.token])

    return (
        <div className=' w-full flex justify-center pt-5 md:pt-10 sm:px-16 md:px-20 lg:px-28'>
            <div className=' w-full flex justify-center'>
                {/* <div className=' w-full'> */}
                <div className=' w-full overflow-x-scroll scrollbar xl:overflow-hidden'>
                    <div className=' w-[800px] xl:w-full bg-white p-3'>
                        <p className=' mb-2 text-center font-medium text-gray-800'>All Users</p>
                        <table className=' w-full'>
                            <tbody>
                                <tr className=' text-sm font-medium'>
                                    <td className=' text-left pb-2'>First Name</td>
                                    <td className=' text-left pb-2'>Last Name</td>
                                    <td className=' text-left pb-2'>Email</td>
                                    <td className=' text-left pb-2'>Phone</td>
                                    <td className=' text-left pb-2'>Whatsapp</td>
                                    <td className=' text-right pb-2'>Actions</td>
                                </tr>
                                {
                                    allusers.length > 0 && allusers.map((user, index) => {
                                        return (
                                            <tr key={index} className=' text-sm font-medium text-gray-700 border-y'>
                                                <td className=' py-3'>
                                                    {
                                                        isUpdateModeOpen && currentUserID.toString() === user._id.toString() ?
                                                            <input onChange={(e) => { setFirstName(e.target.value) }} value={firstName} type="text" placeholder='Frist name' required={true} className='inputFild focus:outline-none' />
                                                            :
                                                            <span className=''>{user.firstName}</span>
                                                    }
                                                </td>
                                                <td className='py-3'>
                                                    {
                                                        isUpdateModeOpen && currentUserID.toString() === user._id.toString() ?
                                                            <input onChange={(e) => { setLastName(e.target.value) }} value={lastName} type="text" placeholder='Last name' required={true} className='inputFild focus:outline-none' />
                                                            :
                                                            <span className=''>{user.lastName}</span>
                                                    }
                                                </td>
                                                <td className=' py-3'>
                                                    {
                                                        isUpdateModeOpen && currentUserID.toString() === user._id.toString() ?
                                                            <input onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder='Email' required={true} className='inputFild focus:outline-none' />
                                                            :
                                                            <span className=''>{user.email}</span>
                                                    }
                                                </td>
                                                <td className='py-3'>
                                                    {
                                                        isUpdateModeOpen && currentUserID.toString() === user._id.toString() ?
                                                            <input onChange={(e) => { setPhone(e.target.value) }} value={phone} type="number" placeholder='Phone number' required={true} className='inputFild focus:outline-none' />
                                                            :
                                                            <span className=''>{user.phone}</span>
                                                    }
                                                </td>
                                                <td className='py-3'>
                                                    {
                                                        isUpdateModeOpen && currentUserID.toString() === user._id.toString() ?
                                                            <input onChange={(e) => { setWhatsappNumber(e.target.value) }} value={whatsappNumber} type="text" required={true} placeholder='Whatsapp Number' className='inputFild focus:outline-none' />
                                                            :
                                                            <span className=''>{user.whatsappNumber}</span>
                                                    }
                                                </td>
                                                <td className=' py-3 text-right'>
                                                    {
                                                        isUpdateModeOpen && currentUserID.toString() === user._id.toString() ?
                                                            <div className=' flex justify-end'>
                                                                <button onClick={() => UpdateUserData(user._id)} className=' bg-blue-500 p-1 rounded text-white hover:bg-blue-600 mr-1'>Save</button>
                                                                <button onClick={toggleUpdateMode} className=' bg-red-500 hover:bg-red-600 text-white  p-1 rounded'>Close</button>
                                                            </div>
                                                            :
                                                            <div className=' flex justify-end'>
                                                                <button onClick={() => togglePasswordUpdateMode(user._id)} className=' bg-green-500 p-1 rounded text-white hover:bg-green-600 mr-1'>Password</button>
                                                                <button onClick={() => toggleUpdateMode(user)} className=' bg-blue-500 p-1 rounded text-white hover:bg-blue-600 mr-1'>Edit</button>
                                                                <button onClick={() => DeleteUser(user._id)} className=' bg-red-500 hover:bg-red-600 text-white  p-1 rounded'>Delete</button>
                                                            </div>
                                                    }

                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* </div> */}
            </div>
            {
                passwordUpdateMode &&
                <div className=' absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-40 flex justify-center z-50'>
                    <form onSubmit={(e) => passwordUpdate(e, currentUserID)} className=' bg-white shadow p-5 w-[400px] h-[400px] mt-20 sm:mt-40 relative '>
                        <button onClick={togglePasswordUpdateMode} className=' bg-red-500 px-3 py-[2px] rounded text-white absolute top-5 right-5' >x</button>
                        <p className=' text-center font-medium'>Password Change</p>
                        <div className=' mt-4'>
                            <label htmlFor="Password" className=' font-medium text-sm'>Password</label>
                            <input id='Password' onChange={(e) => { setPassword(e.target.value) }} value={password} type="password" placeholder='Password' required={true} className='inputFild focus:outline-none' />
                        </div>
                        <div className=' mt-1'>
                            <label htmlFor="Confirm Password" className=' font-medium text-sm'>Confirm Password</label>
                            <input id='Confirm Password' onChange={(e) => { setConfirmPassword(e.target.value) }} value={confirmPassword} type="password" placeholder='Password' required={true} className='inputFild focus:outline-none' />
                        </div>
                        <div className=' mt-1'>
                            <label htmlFor="Admin Password" className=' font-medium text-sm'>Admin Password</label>
                            <input id='Admin Password' onChange={(e) => { setAdminPassword(e.target.value) }} value={adminPassword} type="password" placeholder='Password' required={true} className='inputFild focus:outline-none' />
                        </div>
                        <button type='submit' className='w-full border-[1px] rounded p-2 bg-blue-500 text-white hover:bg-blue-600 transition-all duration-100 ease-in mt-2'>Update</button>
                    </form>
                </div>
            }

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default AllUsers