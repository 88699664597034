import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    Companies: [],
    isLoading: false,
    error: "",
}

export const CompaniesSlice = createSlice({
    name: 'Companies',
    initialState,
    reducers: {
        AllCompanyFatchSuccess: (state, action) => {
            state.Companies = action.payload;
            state.isLoading = false;
            state.error = '';
        },
        AddCompanySuccess: (state, action) => {
            state.Companies.push(action.payload);
            state.isLoading = false;
            state.error = '';
        },
        DeleteCompanySuccess: (state, action) => {
            state.Companies = state.Companies.filter(company => company._id !== action.payload);
            state.isLoading = false;
            state.error = '';
        },
        UpdateCompanySuccess: (state, action) => {
            const index = state.Companies.findIndex(company => company._id === action.payload._id);
            state.Companies[index] = action.payload;
            state.isLoading = false;
            state.error = '';
        }
    },
})

export const { AllCompanyFatchSuccess, AddCompanySuccess, DeleteCompanySuccess, UpdateCompanySuccess } = CompaniesSlice.actions

export default CompaniesSlice.reducer