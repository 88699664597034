import React, { useState } from 'react';
import { Host } from '../../Data';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { adminLoginSuccess } from '../../Redux/Admin_slice';
import { Logout } from "../../Redux/User_slice"
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminLogin() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigation = useNavigate();


    const LoginFunc = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            toast.error('Please fill all the fields');
        } else {

            try {
                const loginData = {
                    email,
                    password
                }

                const res = await axios.post(`${Host}/api/admins/signin`, loginData);

                dispatch(adminLoginSuccess(res.data));
                dispatch(Logout())
                navigation('/admin/dashboard');

            } catch (error) {
                toast.error(error.response.data.error);
            }
        }
    }


    const Switch = (option) => {
        navigation(`/${option}/login`)
    }

    return (
        <div className=' bg-gray-100 h-screen w-full flex justify-center items-center relative px-5'>
            <div className=' w-full sm:w-[450px]'>
                <div className=' flex justify-center mb-2'>
                    <button onClick={() => Switch("user")} className="bg-purple-500 shadow rounded px-1 sm:px-2 py-1 sm:py-[6px] text-white">User</button>
                    <button onClick={() => Switch("company")} className="bg-purple-500 shadow rounded px-1 sm:px-2 py-1 sm:py-[6px] text-white ml-2">Company</button>
                </div>
                <div className=' w-full sm:w-[450px] bg-white rounded-lg py-5 shadow shadow-gray-200'>
                    <h2 className=' text-center text-lg sm:text-2xl pt-5 font-semibold'>Admin Login</h2>

                    <form className=' w-full py-5 px-5 sm:px-10' onSubmit={LoginFunc}>
                        <div className=' w-full'>
                            <label htmlFor="" className=' font-medium text-sm sm:text-base'>Email</label>
                            <input onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder='Email' required={true} className=' w-full p-1 sm:p-2 my-1 sm:my-2 border-[1px] border-gray-300 rounded focus:outline-none' />
                        </div>
                        <div className=' w-full'>
                            <label htmlFor="" className=' font-medium text-sm sm:text-base'>Password</label>
                            <input onChange={(e) => { setPassword(e.target.value) }} value={password} type="password" placeholder='Password' required={true} className=' w-full p-1 sm:p-2 my-1 sm:my-2 border-[1px] border-gray-300 rounded focus:outline-none' />
                        </div>
                        <div className=' w-full flex justify-center mt-2 sm:mt-5'>
                            <button type='submit' className=' w-full text-white font-semibold  py-1 sm:py-2 bg-blue-600 hover:bg-blue-700 rounded '>Login</button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default AdminLogin