import React, { useEffect, useState } from 'react'
import { AiFillCaretDown, AiOutlineLogin } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import logo from "../Photos/logo.png"
import { FaUserAlt, FaUserCog } from 'react-icons/fa';
import { CompanyUserLogout } from "../../Redux/CompanyUser_slice"
import { useDispatch } from 'react-redux';

function Header() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dispatch = useDispatch()
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }
    const LogoutFunc = () => {
        dispatch(CompanyUserLogout())
    }
    useEffect(() => {
        document.addEventListener('mouseup', function (e) {
            if (isDropdownOpen) {
                let container = document.getElementById('container');
                let menu = document.getElementById('menu');
                if (!menu.contains(e.target) && !container.contains(e.target)) {
                    setIsDropdownOpen(!isDropdownOpen)
                }
            }
        });
    }, [isDropdownOpen])

    return (
        <div className=' w-full h-[50px] sm:h-[72px] bg-white shadow shadow-gray-300 fixed top-0 left-0 z-50'>
            <div className=' h-full w-full flex justify-between items-center px-5 sm:px-12'>
                <div className=' flex items-center'>
                    <Link to='/company/dashboard' className='font-semibold'>
                        <img src={logo} alt='logo' className='h-8 w-16' />
                    </Link>
                </div>
                <div id='menu' onClick={toggleDropdown} className=' flex items-center cursor-pointer'>
                    <FaUserAlt className=' text-sm sm:text-lg' />
                    <AiFillCaretDown className={`${isDropdownOpen ? "  rotate-180" : " rotate-0"} transition-all ease-in-out duration-300 text-sm sm:text-lg`} />
                    <span className=' capitalize ml-[2px] text-[12px] sm:text-sm'>Company</span>
                </div>
                {
                    isDropdownOpen &&
                    <div id='container' className=' p-2 bg-white absolute top-12 sm:top-16 right-2 shadow shadow-gray-200 rounded text-gray-700  text-sm sm:text-base'>
                        <Link to={`/company/profile`} className=''>
                            <div className={`rounded p-1 flex items-center hover:bg-gray-100 hover:text-gray-800`}>
                                <FaUserCog className=' mr-2 ' />
                                <p className=''>Profile</p>
                            </div>
                        </Link>
                        <Link to={`/company/profile/password-change`} className=''>
                            <div className={`rounded p-1 flex items-center hover:bg-gray-100 hover:text-gray-800`}>
                                <FaUserCog className=' mr-2' />
                                <p className=''>Password Change</p>
                            </div>
                        </Link>

                        <Link onClick={LogoutFunc} to={`/company/login`} className=' text-red-400'>
                            <div className={`rounded p-1 flex items-center hover:bg-red-300 hover:text-white`}>
                                <AiOutlineLogin className=' mr-2' />
                                <p className=''>Logout</p>
                            </div>
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
}

export default Header