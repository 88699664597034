import React from 'react'
import { AiFillDatabase, AiOutlineHome } from 'react-icons/ai'
import { FaUserFriends } from 'react-icons/fa'
import MenuItem from './MenuItem'

function Sidebar() {
    return (
        <div className={`bg-white pt-24 shadow shadow-gray-300 w-[60px] sm:w-[200px] min-h-screen rounded px-1 sm:px-3`}>
            <ul>
                <MenuItem item={{ name: "Dashboard", link: "/company/dashboard", icon: AiOutlineHome }} />
                <MenuItem item={{ name: "Dataset", link: "/company/dataset", icon: FaUserFriends }} />
                <MenuItem item={{ name: "Bulkdata", link: "/company/bulkdata", icon: AiFillDatabase }} />
            </ul>
        </div>
    )
}

export default Sidebar