import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Layout from "./UserLayout";


function UserPrivateRoute({ children }) {
    const User = useSelector(state => state.User.User);
    return (
        User ? <Layout>{children}</Layout> : <Navigate to="/user/login" />
    )
}

export default UserPrivateRoute