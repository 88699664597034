import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";
// admin
import AdminPrivateRoute from "./Admin/Components/AdminPrivateRoute"
import AdminDashboard from "./Admin/Pages/AdminDashboard";
import AdminLogin from "./Admin/Pages/AdminLogin";
import AdminProfile from "./Admin/Pages/Profile";
import AdminPasswordChange from "./Admin/Pages/AdminPasswordChange";
import AdminInfoUpdate from "./Admin/Pages/AdminInfoUpdate";
import AddUser from "./Admin/Pages/AddUser";
import AllUsers from "./Admin/Pages/AllUsers";
// user
import PrivateRoute from "./User/Components/UserPrivateRoute";
import UserLogin from "./User/Pages/Login";
import UserProfile from "./User/Pages/UserProfile";
import UserPasswordChange from "./User/Pages/UserPasswordChange";
import UserInfoUpdate from "./User/Pages/UserInfoUpdate";
import UserDashboard from "./User/Pages/UserDashboard";
import SingleCompany from "./User/Pages/SingleCompany";
import CreateCompany from "./User/Pages/CreateCompany";
import AllCompanies from "./User/Pages/AllCompanies";
// company
import CompanyPrivateRoute from "./Company/Components/CompanyPrivateRoute"
import CompanyLogin from "./Company/Pages/CompanyLogin";
import CompanyDashboard from "./Company/Pages/CompanyDashboard";
import CompanyDataset from "./Company/Pages/CompanyDataset";
import SingleDataset from "./Company/Pages/SingleDataset";
import BulkDataUpload from "./Company/Pages/BulkData";
import CompanyUserProfile from "./Company/Pages/CompanyUserProfile";
import CompanyUserPasswordChange from "./Company/Pages/CompanyUserPasswordChange";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute><UserDashboard /></PrivateRoute>} />
        {/* admin routes */}
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={<AdminPrivateRoute><AdminDashboard /></AdminPrivateRoute>} />
        <Route path="/admin/profile" element={<AdminPrivateRoute><AdminProfile /></AdminPrivateRoute>} />
        <Route path="/admin/profile/password-change" element={<AdminPrivateRoute><AdminPasswordChange /></AdminPrivateRoute>} />
        <Route path="/admin/profile/update-info" element={<AdminPrivateRoute><AdminInfoUpdate /></AdminPrivateRoute>} />
        <Route path="/admin/add-user" element={<AdminPrivateRoute><AddUser /></AdminPrivateRoute>} />
        <Route path="/admin/all-users" element={<AdminPrivateRoute><AllUsers /></AdminPrivateRoute>} />
        {/* user routes */}
        <Route path="/user/login" element={<UserLogin />} />
        <Route path="/user/profile" element={<PrivateRoute><UserProfile /></PrivateRoute>} />
        <Route path="/user/profile/password-change" element={<PrivateRoute><UserPasswordChange /></PrivateRoute>} />
        <Route path="/user/profile/update-info" element={<PrivateRoute><UserInfoUpdate /></PrivateRoute>} />
        <Route path="/user/add-company" element={<PrivateRoute><CreateCompany /></PrivateRoute>} />
        <Route path="/user/all-companies" element={<PrivateRoute><AllCompanies /></PrivateRoute>} />
        <Route path="/singleCompany/:id" element={<PrivateRoute><SingleCompany /></PrivateRoute>} />
        {/* company routes */}
        <Route path="/company/login" element={<CompanyLogin />} />
        <Route path="/company/dashboard" element={<CompanyPrivateRoute><CompanyDashboard /></CompanyPrivateRoute>} />
        <Route path="/company/profile" element={<CompanyPrivateRoute><CompanyUserProfile /></CompanyPrivateRoute>} />
        <Route path="/company/profile/password-change" element={<CompanyPrivateRoute><CompanyUserPasswordChange /></CompanyPrivateRoute>} />
        <Route path="/company/silgle-dataset/:id" element={<CompanyPrivateRoute><SingleDataset /></CompanyPrivateRoute>} />
        <Route path="/company/dataset" element={<CompanyPrivateRoute><CompanyDataset /></CompanyPrivateRoute>} />
        <Route path="/company/bulkdata" element={<CompanyPrivateRoute><BulkDataUpload /></CompanyPrivateRoute>} />
      </Routes>
    </HashRouter>
  );
}

export default App;