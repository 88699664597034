import React from 'react'
import { AiOutlineHome } from 'react-icons/ai'
import { BsClipboardData } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { FaUserCog, FaUserFriends } from 'react-icons/fa'
import MenuItem from './MenuItem'

function UserSidebar() {
    return (
        <div className={`bg-white pt-24 shadow shadow-gray-300 w-[60px] sm:w-[200px] min-h-screen rounded px-1 sm:px-3`}>
            <ul>
                <MenuItem item={{ name: "Dashboard", link: "/", icon: AiOutlineHome }} />
                {/* <MenuItem item={{ name: "Clients", link: "/user/showClientData", icon: BsClipboardData }} /> */}
                <MenuItem item={{ name: "Companies", link: "/user/all-companies", icon: FaUserFriends, child: [{ name: "Create", link: "/user/add-company", icon: FaUserCog }, { name: "Companies", link: "/user/all-companies", icon: FaUserFriends }] }} />
            </ul>
        </div>
    )
}

export default UserSidebar