import axios from 'axios';
import React, { useState } from 'react'
import { AiFillCloseCircle, AiFillDelete, AiFillEdit, AiFillSave } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Host } from '../../Data';

function Keys({ getCompany, currantCompany }) {

    const location = useLocation();
    let companyId = location.pathname.split('/')[2];
    const [keyName, setKeyName] = useState('');
    const [type, setType] = useState("")
    const User = useSelector(state => state.User.User);
    const [isUpdateModeOpen, setIsUpdateModeOpen] = useState(false);
    const [isAddModeOpen, setIsAddModeOpen] = useState(false);
    const [currantKey, setCurrantKey] = useState(null);
    const [keyActibationStatus, setKeyActibationStatus] = useState(true);
    const [selectedKeyType, setSelectedKeyType] = useState("")

    const toggleAddMode = () => {
        setIsAddModeOpen(!isAddModeOpen);
        setKeyName('');
        if (isUpdateModeOpen) {
            setIsUpdateModeOpen(false)
        }
    }

    const CreateKeyFunc = async () => {
        try {
            await axios.post(`${Host}/api/companies/${companyId}/keys`, { key: keyName, type }, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })
            setKeyName('');
            setType("")
            getCompany()
            toast.success('Keys created successfully');
        } catch (err) {
            toast.error(err.response.data.error)
        }
    };

    const updateKeyName = async (keyId) => {
        if (!keyName) {
            toast.error("key name is required")
            return
        }
        try {
            const keyData = {
                keyName: keyName,
                isActive: keyActibationStatus,
                type: selectedKeyType
            }
            await axios.put(`${Host}/api/companies/keys/${keyId}`, keyData, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })
            getCompany()
            toast.success('Key name updated successfully');
            setIsUpdateModeOpen(false)
        } catch (error) {
            toast.error(error.response.data.error)
        }
    }


    const deleteKey = async (keyId) => {
        const confirmDalete = window.confirm("Are you sure you want to delete the key.")
        if (!confirmDalete) {
            return
        }
        try {
            await axios.delete(`${Host}/api/companies/keys/${keyId}`, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })
            getCompany()
            toast.success('Key deleted successfully');
        } catch (err) {
            toast.error(err.response.data.error);
        }
    }


    const toggleUpdateMode = (key) => {
        setIsUpdateModeOpen(!isUpdateModeOpen);
        setCurrantKey(key.keyName);
        setKeyName(key.keyName);
        setSelectedKeyType(key.type)
        if (key.isActive === true) {
            setKeyActibationStatus("true");
        } else {
            setKeyActibationStatus("false");
        }
    }

    // console.log(currantCompany)

    return (
        <div className=' bg-white rounded shadow shadow-gray-300 p-5'>
            <div className=' relative mb-5'>
                <p className='text-center font-semibold'>Company Keys</p>
                <div className=' mt-1 sm:mt-0 flex justify-end'>
                    <button onClick={toggleAddMode} className=' border-[1] rounded p-1 text-green-500 shadow shadow-green-300 ml-2 hover:scale-105 hover:text-green-600 transition-all duration-100 ease-in'>Add Key</button>
                </div>
                {
                    isAddModeOpen &&
                    <div className=' fixed top-0 left-0 z-50 w-full h-screen bg-black bg-opacity-25 flex justify-center p-5'>
                        <div className=' w-full sm:w-[400px] h-[250px] p-2 sm:p-5 bg-white shadow rounded  relative mt-20 sm:mt-32'>
                            <p className=' text-center text-gray-700 font-medium'>Add Key</p>
                            <div className=' flex my-5 items-center'>
                                <label htmlFor="" className=' mr-2 font-medium text-gray-700 sm:text-base text-sm'>Key Name : </label>
                                <input type="text" placeholder='Enter Keys Name' value={keyName} onChange={(e) => { setKeyName(e.target.value) }} className='focus:outline-none border border-blue-500 rounded py-1 px-2 mr-2' />
                                <AiFillCloseCircle onClick={toggleAddMode} className='text-red-500 absolute top-3 right-3 cursor-pointer text-2xl ml-2' />
                            </div>
                            <div>
                                <label htmlFor="" className=' mr-2 font-medium text-gray-700 sm:text-base text-sm'>Key Type</label>
                                <select name="" id="" value={type} onChange={(e) => setType(e.target.value)} className="p-1 border  border-blue-500 rounded focus:outline-none">
                                    <option value="">Select Type</option>
                                    <option value="as-it-is">Default</option>
                                    <option value="date">Date</option>
                                </select>
                            </div>
                            <div className=' flex justify-center mt-5'>
                                {/* <AiFillSave onClick={CreateKeyFunc} className='text-blue-500 cursor-pointer text-2xl' /> */}
                                <button onClick={CreateKeyFunc} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in'>Create</button>
                                {/* <button onClick={toggleAddMode} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in'>Close</button> */}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <table className=' w-full'>
                <thead>
                    <tr>
                        <th className=' text-gray-700 font-medium py-1 text-left'>Name</th>
                        <th className=' text-gray-700 font-medium py-1'>Status</th>
                        <th className=' text-gray-700 font-medium py-1'>Type</th>
                        <th className=' text-gray-700 font-medium py-1 text-right'>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currantCompany.keys.map((key, index) => {
                            return (
                                <tr key={index} className=' py-1 border-b-[1px]'>
                                    <td>
                                        {
                                            isUpdateModeOpen && currantKey && currantKey === key.keyName ?
                                                <input className=' focus:outline-none border-[1px] border-blue-400 rounded mr-5 py-[6px] px-2' placeholder='Enter Company Name' value={keyName} onChange={(e) => setKeyName(e.target.value)} />
                                                :
                                                <div className=' text-gray-700 font-semibold text-sm flex items-center'>
                                                    <span>{key.keyName}</span>
                                                </div>
                                        }
                                    </td>

                                    <td>
                                        {
                                            isUpdateModeOpen && currantKey && currantKey === key.keyName ?
                                                <select name="" id="" value={keyActibationStatus} onChange={(e) => setKeyActibationStatus(e.target.value)} className=' border border-blue-500 rounded py-1 focus:outline-none'>
                                                    <option value="">Select Status</option>
                                                    <option value={true}>Active</option>
                                                    <option value={false}>Diactive</option>
                                                </select>
                                                :
                                                <div className='flex items-center justify-center'>
                                                    <span className={`${key.isActive ? " bg-blue-50 text-[12px] font-semibold text-blue-700 rounded p-1" : "bg-red-50 text-red-700 text-sm font-semibold rounded p-1"}`}>{key.isActive ? "Active" : "Diactivated"}</span>
                                                </div>

                                        }
                                    </td>
                                    <td>
                                        {
                                            isUpdateModeOpen && currantKey && currantKey === key.keyName ?
                                                <select name="" id="" value={selectedKeyType} onChange={(e) => setSelectedKeyType(e.target.value)} className=' border border-blue-500 rounded py-1 focus:outline-none'>
                                                    <option value="">Select Type</option>
                                                    <option value="as-it-is">Default</option>
                                                    <option value="date">Date</option>
                                                </select>
                                                :
                                                <div className=''>
                                                    <span>{key.type && key.type}</span>
                                                </div>

                                        }
                                    </td>
                                    <td>
                                        {
                                            isUpdateModeOpen && currantKey && currantKey === key.keyName ?
                                                <div className=' flex items-center justify-end'>
                                                    <AiFillSave onClick={() => updateKeyName(key._id)} className='text-blue-500 cursor-pointer text-xl' />
                                                    <AiFillCloseCircle onClick={() => setIsUpdateModeOpen(false)} className='text-red-500 cursor-pointer text-xl ml-2' />
                                                    {/* <button onClick={() => updateKeyName(key._id)} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in'>Save</button> */}
                                                    {/* <button onClick={() => setIsUpdateModeOpen(false)} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in'>Close</button> */}
                                                </div>
                                                :
                                                <div className=' flex items-center justify-end'>
                                                    <AiFillEdit onClick={() => toggleUpdateMode(key)} className=" text-green-500 text-lg cursor-pointer" />
                                                    <AiFillDelete onClick={() => { deleteKey(key._id) }} className=" text-red-500 text-lg cursor-pointer ml-2" />
                                                    {/* <button onClick={() => toggleUpdateMode(key.keyName, key.isActive, key.type, key._id)} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in'>Edit</button> */}
                                                    {/* <button onClick={() => { deleteKey(key._id) }} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in'>Delete</button> */}
                                                </div>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Keys