import axios from "axios";
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Host } from "../../Data";
import { allusersLoadingSuccess } from "../../Redux/Allusers_slice"

function AdminDashboard() {

    const Admin = useSelector((state) => state.Admin.Admin);
    const allUsers = useSelector((state) => state.Allusers.Allusers);
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${Host}/api/admins/users`, {
                    headers: {
                        'Authorization': `Bearer ${Admin.token}`
                    }
                })
                dispatch(allusersLoadingSuccess(response.data.data));
                // console.log(response)
            } catch (err) {
                console.log(err);
            }
        }
        fetchUsers();
    }, [])

    console.log(allUsers)

    return (
        <div className='w-full pt-5 md:pt-10 sm:px-10 md:px-16 lg:px-20 xl:px-32'>
            <div className='w-full'>
                <div className={` w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5 mb-5`}>
                    <div className=' w-full bg-white shadow shadow-gray-300 p-5 h-40 rounded'>
                        <p className=' text-gray-500'>Total User</p>
                        <p className=' text-xl font-semibold my-3'>{allUsers.length}</p>
                        <p className=' text-gray-500 font-semibold text-sm'><span className=' bg-blue-100 text-blue-600 text-sm mr-2 rounded'>+$20.9k</span>Since last week</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AdminDashboard