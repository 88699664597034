import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Host } from '../../Data'
import { AllCompanyFatchSuccess } from '../../Redux/Companies_slice'

function UserDashboard() {
    const dispatch = useDispatch()
    const User = useSelector(state => state.User.User);
    const AllCompanies = useSelector(state => state.Companies.Companies);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await axios.get(`${Host}/api/companies`, {
                    headers: {
                        'Authorization': `Bearer ${User.token}`
                    }
                })
                dispatch(AllCompanyFatchSuccess(response.data.data));
            } catch (err) {
                console.log(err);
            }
        }
        fetchCompanies();
    }, [User])

    return (
        <div className='w-full pt-5 md:pt-10 sm:px-10 md:px-16 lg:px-20 xl:px-32'>
            <div className='w-full pr-5'>
                <div className={` w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5 mb-5`}>
                    <Link to="/user/all-companies">
                        <div className=' w-full bg-white shadow shadow-gray-300 p-5 h-40 rounded'>
                            <p className=' text-gray-500'>Total Company</p>
                            <p className=' text-xl font-semibold my-3'>{AllCompanies.length}</p>
                            <p className=' text-gray-500 font-semibold text-sm'><span className=' bg-blue-100 text-blue-600 text-sm mr-2 rounded'>+$20.9k</span>Since last week</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default UserDashboard