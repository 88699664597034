import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Host } from "../../Data"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function UserPasswordChange() {

    const User = useSelector(state => state.User.User);
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const UpdateAdminData = async (e) => {
        e.preventDefault()
        if (password && password !== confirmPassword) {
            toast.error('Password does not match');
            return;
        }
        const passwordChange = {
            oldPassword: oldPassword,
            newPassword: password
        }
        try {
            await axios.put(`${Host}/api/users/password`, passwordChange, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            });
            toast.success('Password updated successfully');
            setConfirmPassword('');
            setPassword('');
            setOldPassword("")
        } catch (error) {
            toast.error(error.response.data.error);
        }
    }

    return (

        <div className='w-full pt-5 md:pt-10 sm:px-10 md:px-16 lg:px-20 xl:px-32'>
            <div className='w-full flex justify-center pr-5'>
                <div className=' w-[50%] bg-white rounded p-5 sm:p-10 relative'>
                    <p className=' text-lg sm:text-xl md:text-center font-semibold mb-5'>Update Password</p>
                    <div className=' flex'>
                        <div className=' w-full'>
                            <form onSubmit={UpdateAdminData} className=' w-full'>
                                <div className=' w-full'>
                                    <label htmlFor="">Old Password</label>
                                    <input onChange={(e) => { setOldPassword(e.target.value) }} value={oldPassword} type="password" placeholder='Password' className='inputFild focus:outline-none' />
                                </div>
                                <div className=' w-full'>
                                    <label htmlFor="">New Password</label>
                                    <input onChange={(e) => { setPassword(e.target.value) }} value={password} type="password" placeholder='Password' className='inputFild focus:outline-none' />
                                </div>
                                <div className=' w-full'>
                                    <label htmlFor="">Confirm Password</label>
                                    <input onChange={(e) => { setConfirmPassword(e.target.value) }} value={confirmPassword} type="password" placeholder='Confirm Password' className='inputFild focus:outline-none' />
                                </div>
                                <div className=' w-full flex justify-center mt-5'>
                                    <button type='submit' className='w-full border-[1px] rounded p-2 bg-blue-500 text-white hover:bg-blue-600 transition-all duration-100 ease-in'>Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default UserPasswordChange