import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti"


function MenuItem({ item }) {

    const location = useLocation();
    const path = location.pathname;
    const [isChildDropdownOpen, setIsChildDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setIsChildDropdownOpen(!isChildDropdownOpen);
    };

    if (item.child) {
        return (
            <li onClick={toggleDropdown} >
                <div className=' text-gray-700 font-medium flex justify-between hover:bg-gray-100 p-2 rounded my-1 cursor-pointer'>
                    <div className=' flex items-center'>
                        <item.icon className="mr-1" />
                        <span className=' hidden sm:block'>{item.name}</span>
                    </div>
                    <div>
                        {isChildDropdownOpen ?
                            <TiArrowSortedUp className=' text-xl' />
                            :
                            <TiArrowSortedDown className=' text-xl' />
                        }
                    </div>
                </div>
                {
                    isChildDropdownOpen ?
                        <ul className=' pl-4'>
                            {
                                item.child.map((it) => {
                                    return (
                                        <MenuItem item={it} />
                                    )
                                })
                            }
                        </ul>
                        :
                        null
                }
            </li>
        )
    } else {
        return (
            <Link to={item.link} >
                <li className={`hover:bg-gray-100 text-gray-700 font-medium p-2 rounded my-1 ${path === item.link && "bg-gray-100 text-gray-800 font-medium"}`}>
                    <div className=' flex items-center'>
                        <item.icon className="mr-1" />
                        <span className=' hidden sm:block'>{item.name}</span>
                    </div>
                </li>
            </Link>
        )
    }
}

export default MenuItem