import React from 'react'
import { useSelector } from 'react-redux'


function CompanyUserProfile() {
    const User = useSelector((state) => state.CompanyUser.CompanyUser)

    return (
        <div className='w-full pt-5 md:pt-10 sm:px-10 md:px-16 lg:px-20 xl:px-32'>
            <div className='w-full flex justify-center pr-5'>
                <div className=' w-[60%] bg-white rounded p-5 relative'>
                    <p className='text-center font-semibold mb-5'>Company User Details</p>
                    <div className=' flex justify-between'>
                        <p className=' font-medium text-gray-800 text-sm'>Email : </p>
                        <p className=' font-medium text-gray-800 text-sm'>{User.email}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompanyUserProfile