import { useSelector } from "react-redux"

function CompanyDashboard() {
    const companyUser = useSelector((state) => state.CompanyUser.CompanyUser)
    return (
        <div className='w-full pt-5 md:pt-10 sm:px-10 md:px-16 lg:px-20 xl:px-32'>
            <div className='w-full pr-5'>
                <div className={` w-full mb-5 flex justify-center`}>
                    <div className=' w-full lg:w-[60%] bg-white shadow shadow-gray-300 p-5 rounded'>
                        <p className=' text-center text-lg font-semibold'>keys</p>
                        <table className=" w-full">
                            <thead>
                                <tr className=" text-sm text-gray-700 bg-gray-100 mt-1">
                                    <th className=" text-left p-1">Name</th>
                                    <th className=" text-right p-1">Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    companyUser.keys.map((key, index) => {
                                        return (
                                            <tr className=" text-sm border-b" key={index}>
                                                <td className=" p-1">{key.keyName}</td>
                                                <td className=" text-right p-1">
                                                    <span className={`${key.type === "date" ? " bg-purple-300 text-purple-700 " : "bg-blue-400 text-white"} font-medium px-1 py-[2px] rounded`}>{key.type}</span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CompanyDashboard