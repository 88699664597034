import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import CompanyLaout from "../Components/CompanyLayout"


function PrivateRoute({ children }) {
    const Company = "test"
    return (
        Company ? <CompanyLaout>{children}</CompanyLaout> : <Navigate to="/company/login" />
    )
}

export default PrivateRoute