import React, { useEffect, useState } from 'react'

function ObjectDataFormat({ value }) {

    const [formateKeys, setFormateKeys] = useState([]);
    const [companyDataFormatArray, setCompanyDataFormatArray] = useState([]);

    const createArrayFromObject = (object) => {
        return Object.keys(object).map(key => object[key]);

    }

    useEffect(() => {
        if (value) {
            setFormateKeys(Object.keys(value));
            setCompanyDataFormatArray(createArrayFromObject(value));
        }
    }, [])


    return (
        <div>
            {
                formateKeys.length > 0 && formateKeys.map((key, index) => {
                    return (
                        <div key={index} className=' my-[2px] ml-5'>
                            {
                                companyDataFormatArray.length > 0 && companyDataFormatArray.map((item, i) => {
                                    if (index === i) {
                                        return (
                                            <p>{key} : <span>{item}</span></p>
                                        )
                                    }
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ObjectDataFormat