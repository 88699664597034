import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Host } from '../../Data'
import UpdateDatasetCompnent from '../Components/UpdateDatasetCompnent'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConditionComponent from '../Components/ConditionComponent'
import SelectComponent from '../Components/SelectComponent'
import { ImDownload3 } from 'react-icons/im'

function SingleDataset() {

    const location = useLocation()
    const path = location.pathname.split("/")[3]
    const companyUser = useSelector((state) => state.CompanyUser.CompanyUser)
    const [dataset, setDataset] = useState(null)
    const [editModeOpen, setEditModeOpen] = useState(false)
    const [CreateConditionModeOpen, setCreateConditionOpen] = useState(false)
    const [showSelectComponentOpen, setShowSelectComponentOpen] = useState(false)
    const [optionsArray, setOptionsArray] = useState([]);
    const [conditionalTimesOptions, setConditionalTimesOption] = useState([])
    const [clientData, setClientData] = useState([])
    const [tableRowOptions, setTableRowOptions] = useState([])
    const [pagination, setPagination] = useState(null)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const toggleShowSelectComponentOpen = () => {
        setShowSelectComponentOpen(!showSelectComponentOpen)
    }

    const toggleCreateConditionMode = () => {
        setCreateConditionOpen(!CreateConditionModeOpen)
    }

    const toggleEditMod = () => {
        setEditModeOpen(!editModeOpen)
    }
    const toggleUpdateDatasetMode = () => {
        setEditModeOpen(!editModeOpen)
    }
    const fatchdata = async function () {
        try {
            const res = await axios.get(`${Host}/api/datasets/${path}`, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            setDataset(res.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fatchdata()
    }, [path])

    useEffect(() => {
        if (dataset?.company) {
            const company = dataset.company
            const options = [];
            const conditionalTimesOptions = []


            Object.keys(company.dataFormat).forEach(key => {
                if (company.dataFormat[key].type === "String") {
                    options.push(key);
                } else if (company.dataFormat[key].type === "Object") {
                    Object.keys(company.dataFormat[key].value).forEach(k => {
                        options.push(`${key}.${k}`);
                    });
                } else if (company.dataFormat[key].type === "Array") {
                    conditionalTimesOptions.push(key)
                    options.push(key);
                } else if (company.dataFormat[key].type === "ArrayObject") {
                    conditionalTimesOptions.push(key)
                    Object.keys(company.dataFormat[key].value).forEach(k => {
                        options.push(`${key}.${k}`);
                    });
                }
            })
            setOptionsArray(options);
            setConditionalTimesOption(conditionalTimesOptions)
            if (!dataset.select.length > 0) {
                let tableRowOptions = Object.keys(company.dataFormat)
                tableRowOptions = tableRowOptions.filter((option) => option !== "_id")
                setTableRowOptions(tableRowOptions.slice(0, 8))
            }
        }
    }, [dataset]);

    // console.log({ optionsArray, conditionalTimesOptions })


    const fatchClientData = async () => {
        try {
            const res = await axios.get(`${Host}/api/datasets/${dataset._id}/clients?page=${page}&limit=${limit}`, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            setClientData(res.data.data)
            setPagination(res.data.pagination)

            if (dataset.select.length > 0) {
                setTableRowOptions(dataset.select)
            }

        } catch (error) {
            console.log(error)
        }
    }
    // console.log(tableRowOptions)
    const updateStatus = async (value, dataset) => {
        try {
            await axios.put(`${Host}/api/datasets/${dataset._id}/status`, { status: value }, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            fatchdata()
            toast.success("Dataset Status Updated")
        } catch (error) {
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        fatchClientData()
    }, [page, limit])

    const gotoNext = async () => {
        setPage(pagination.next)
    }

    const gotoPrevious = async () => {
        setPage(pagination.prev)
    }
    const changeDataPerPage = (number) => {
        setLimit(number)
    }

    return (
        <div className=' p-0 sm:p-5'>
            {
                editModeOpen &&
                <UpdateDatasetCompnent updateAbleDataset={dataset} toggleUpdateDatasetMode={toggleUpdateDatasetMode} fatchdata={fatchdata} />
            }
            {
                CreateConditionModeOpen && dataset &&
                <ConditionComponent fatchdata={fatchdata} toggleCreateConditionMode={toggleCreateConditionMode} dataset={dataset} optionsArray={optionsArray} conditionalTimesOptions={conditionalTimesOptions} setDataset={setDataset} />
            }
            {
                showSelectComponentOpen && dataset &&
                <SelectComponent toggleShowSelectComponentOpen={toggleShowSelectComponentOpen} dataset={dataset} optionsArray={optionsArray} setDataset={setDataset} fatchdata={fatchdata} />
            }
            {
                dataset &&
                <div className=' bg-white shadow rounded p-5'>
                    <p className=' text-center text-gray-800 mb-3 font-medium'>Dataset Details</p>
                    <div className=' flex justify-between flex-wrap'>
                        <div>
                            <p className=' mb-2 mt-3'><span className=' font-medium text-sm'>Name</span> : <span className=' text-sm text-gray-700'>{dataset.name}</span></p>
                            <p className=' my-2'><span className=' font-medium text-sm'>Description</span> : <span className=' text-sm text-gray-700'>{dataset.description}</span></p>
                            <div className=' flex items-center my-2'>
                                <span className=' font-medium text-sm mr-2'>Status : </span>
                                <select name="" id="" value={dataset.status} onChange={(e) => updateStatus(e.target.value, dataset)} className={`focus:outline-none  rounded text-gray-800 ${dataset.status == true ? " bg-blue-50" : " bg-red-300"}`}>
                                    <option value={true}>True</option>
                                    <option value={false}>False</option>
                                </select>
                            </div>
                            <div className=' flex items-center my-2'>
                                <p className=''><span className=' font-medium text-sm'>Select</span> : <span className=' text-sm text-gray-700'>{dataset.select.length}</span></p>
                                <button onClick={toggleShowSelectComponentOpen} className=' shadow shadow-blue-300 py-[2px] px-1 rounded text-blue-700 transition-all ease-linear duration-200 hover:scale-105 ml-2 font-medium text-sm'>View</button>
                            </div>
                            <div className=' flex items-center my-2'>
                                <p className=''><span className=' font-medium text-sm'>Conditions</span> : <span className=' text-sm text-gray-700'>{dataset.conditions.length}</span></p>
                                <button onClick={toggleCreateConditionMode} className=' shadow shadow-blue-300 py-[2px] px-1 rounded text-blue-700 transition-all ease-linear duration-200 hover:scale-105 ml-2 font-medium text-sm'>View</button>
                            </div>
                        </div>
                        <div>
                            <div className=' flex'>
                                <a href={`${Host}/api/datasets/${dataset._id}/download/${companyUser.token}`} target="_blank" rel="noopener noreferrer" >
                                    <div className=' cursor-pointer flex justify-center items-center shadow mr-2 shadow-blue-300 py-1 px-2 rounded text-blue-700 transition-all ease-linear duration-200 hover:scale-105 font-medium text-sm'>
                                        <ImDownload3 className=' text-blue-500 mr-1 text-lg' />
                                    </div>
                                </a>
                                <button onClick={toggleEditMod} className=' shadow shadow-green-300 py-1 px-2 rounded text-green-700 transition-all ease-linear duration-200 hover:scale-105 font-medium text-sm'>Edit</button>
                                <button onClick={fatchClientData} className=' shadow shadow-purple-300 py-1 px-2 rounded text-purple-700 ml-2 transition-all ease-linear duration-200 hover:scale-105 font-medium text-sm'>Fatch Data</button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="" className=' font-medium text-sm '>Data per page</label>
                        <select value={limit} onChange={(e) => changeDataPerPage(e.target.value)} name="" id="" className='ml-1 border border-blue-300 p-[2px] rounded focus:outline-none'>
                            <option value="">Select Number</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                            <option value={60}>60</option>
                            <option value={70}>70</option>
                            <option value={80}>80</option>
                            <option value={90}>90</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                    <div className=' mt-2 '>
                        {
                            pagination && (pagination.next || pagination.prev) &&
                            <div className=' my-3'>
                                <button onClick={gotoPrevious} className={`  shadow shadow-purple-300 py-[2px] px-1 rounded text-purple-700 mr-2 transition-all ease-linear duration-200 hover:scale-105 disabled:shadow-none disabled:scale-100`} disabled={!pagination.prev}>Pre</button>
                                <button onClick={gotoNext} disabled={!pagination.next} className={` shadow shadow-purple-300 py-[2px] px-1 rounded text-purple-700  transition-all ease-linear duration-200 hover:scale-105 disabled:shadow-none disabled:scale-100`}>Next</button>
                            </div>
                        }
                        <table className=' w-full'>
                            <thead>
                                <tr className=' bg-gray-100'>
                                    {
                                        tableRowOptions.map((s, i) => {
                                            return (
                                                <th key={i} className=" first:text-left last:text-right font-medium text-gray-700 p-1">{s}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    clientData.length > 0 && clientData.map((client, index) => {
                                        return (
                                            <tr>
                                                {
                                                    tableRowOptions.map((option, i) => {
                                                        return (
                                                            <td className=' first:text-left last:text-right text-center p-1 text-sm text-gray-600'>{client[option]}</td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>

                    </div>
                </div>
            }
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default SingleDataset