import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Host } from '../../Data';
import { AddCompanySuccess } from "../../Redux/Companies_slice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function CreateCompany() {

    const User = useSelector(state => state.User.User);
    const dispatch = useDispatch()
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const CreateCompanyFunc = async (e) => {
        e.preventDefault()
        const companyData = {
            companyName,
            email,
            password
        }
        try {
            const response = await axios.post(`${Host}/api/companies`, companyData, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })
            setCompanyName('');
            setEmail("")
            setPassword("")
            dispatch(AddCompanySuccess(response.data.data));
            // console.log(response.data)
            toast.success('Company Created Successfully')
        } catch (err) {
            toast.error(err.response.data.error)
        }
    };


    return (
        <div className='w-full lg:overflow-hidde'>
            <div className=' w-full flex justify-center mt-5 sm:mt-10 '>
                <div className=' w-full sm:w-[500px] bg-white shadow py-10 px-5 sm:px-10 rounded'>
                    <div className=''>
                        <p className='font-semibold text-center mb-3 text-lg'>Create Company</p>
                        <div className=''>
                            <form onSubmit={CreateCompanyFunc} className=' w-full'>
                                <div className=' mb-2'>
                                    <label htmlFor="" className=' font-medium text-sm sm:text-base text-gray-700'>Company Name:</label>
                                    <input type="text" required={true} placeholder='Company Name' value={companyName} onChange={(e) => { setCompanyName(e.target.value) }} className=' w-full focus:outline-none border rounded mr-5 py-1 px-2 mt-[2px]' />
                                </div>
                                <div className=' mb-2'>
                                    <label htmlFor="" className=' font-medium text-sm sm:text-base text-gray-700'>User Email</label>
                                    <input type="email" required={true} placeholder='example@gmail.com' value={email} onChange={(e) => { setEmail(e.target.value) }} className=' w-full focus:outline-none border rounded mr-5 py-1 px-2 mt-[2px]' />
                                </div>
                                <div className=' mb-2'>
                                    <label htmlFor="" className=' font-medium text-sm sm:text-base text-gray-700'>User Password</label>
                                    <input type="password" required={true} placeholder='*************' value={password} onChange={(e) => { setPassword(e.target.value) }} className=' w-full focus:outline-none border rounded mr-5 py-1 px-2 mt-[2px]' />
                                </div>
                                <div className=''>
                                    <button type="submit" value="Submit" className=' border-[1px] rounded p-1 bg-blue-500 text-white w-full mt-2'>Create</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default CreateCompany