import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    Keys: [],
    isLoading: false,
    error: "",
}

export const KeysSlice = createSlice({
    name: 'Keys',
    initialState,
    reducers: {
        AllKeysFatchSuccess: (state, action) => {
            state.Keys = action.payload;
            state.isLoading = false;
            state.error = '';
        },
        AddKeySuccess: (state, action) => {
            state.Keys.push(action.payload);
            state.isLoading = false;
            state.error = '';
        },
        DeleteKeySuccess: (state, action) => {

            state.Keys = state.Keys.filter((key) => key.companyId !== action.payload.companyId && key.key !== action.payload.key)

            state.isLoading = false;
            state.error = '';
        },
        UpdateKeySuccess: (state, action) => {
            const index = state.Keys.findIndex(key => key.companyId === action.payload.companyId && key.key === action.payload.oldKey);
            state.Keys[index] = action.payload;
            state.isLoading = false;
            state.error = '';
        }
    },
})

export const { AllKeysFatchSuccess, AddKeySuccess, UpdateKeySuccess, DeleteKeySuccess } = KeysSlice.actions

export default KeysSlice.reducer