import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Host } from '../../Data'
import 'react-toastify/dist/ReactToastify.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from 'react-toastify';

function SelectComponent({ toggleShowSelectComponentOpen, dataset, setDataset, optionsArray, fatchdata }) {
    const [addModeOpen, setAddModeOpen] = useState(false)
    const companyUser = useSelector((state) => state.CompanyUser.CompanyUser)
    const [select, setSelect] = useState([])
    const [addSelect, setAddSelect] = useState("")

    const toggleAddMode = () => {
        setAddSelect("")
        setAddModeOpen(!addModeOpen)
    }

    const saveSelecet = async () => {
        try {
            const res = await axios.put(`${Host}/api/datasets/${dataset._id}/selects`, { selects: [...select, addSelect] }, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            setDataset({ ...dataset, select: res.data.data })
            setAddSelect("")
            toast.success("Select add success")
        } catch (error) {
            toast.error("something went wrong")
            console.log(error)
        }
    }

    const deltetSelect = async (select) => {
        try {
            const selectData = dataset.select.filter((sel) => sel !== select)
            await axios.put(`${Host}/api/datasets/${dataset._id}/selects`, { selects: selectData }, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            setDataset({ ...dataset, select: selectData })
            toast.success("Delete Success")
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }
    }

    useEffect(() => {
        setSelect(dataset.select)
    }, [dataset])


    // drag and drop
    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            select,
            result.source.index,
            result.destination.index
        );
        try {
            setSelect(items)
            const res = await axios.put(`${Host}/api/datasets/${dataset._id}/selects`, { selects: items }, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            setDataset({ ...dataset, select: res.data.data })
            toast.success("select position changed")
        } catch (error) {
            toast.error(error.response.data.error)
            console.log(error)
        }
    }


    return (
        <div className=' fixed top-0 left-0 z-50 w-full h-screen p-5 bg-black bg-opacity-25 flex justify-center'>

            <div className=' w-full sm:w-[600px]'>
                <div className=' w-full bg-white sm:mt-32 rounded shadow p-5 relative'>
                    <div onClick={toggleShowSelectComponentOpen} className=' cursor-pointer flex justify-center items-center absolute top-2 right-2'>
                        <button className='shadow shadow-red-300 py-[2px] px-1 rounded text-red-700 transition-all ease-linear duration-200 hover:scale-105 font-medium'>Close</button>
                    </div>
                    <p className='sm:text-lg font-medium text-center text-gray-900'>Select Datails</p>
                    {
                        addModeOpen ?
                            <div className=' flex justify-between items-center'>
                                <div className=' mt-3'>
                                    <label htmlFor="" className=' font-medium'>Name : </label>
                                    <select name="" id="" className=' border rounded p-[2px] focus:outline-none' value={addSelect} onChange={(e) => setAddSelect(e.target.value)}>
                                        <option value="">Select Option</option>
                                        {
                                            optionsArray.map((option, index) => {
                                                return (
                                                    <option key={index} value={option}>{option}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <button onClick={saveSelecet} className='shadow shadow-blue-300 py-[2px] px-1 rounded text-blue-700 mr-2 transition-all ease-linear duration-200 hover:scale-105 font-medium'>Save</button>
                                    <button onClick={toggleAddMode} className='  shadow shadow-red-300 py-[2px] px-1 rounded text-red-700 mr-2 transition-all ease-linear duration-200 hover:scale-105 font-medium'>Close</button>
                                </div>
                            </div>
                            :
                            <div className=' w-full flex justify-end '>
                                <button onClick={toggleAddMode} className='shadow shadow-blue-300 py-[2px] px-1 rounded text-blue-700 mr-2 transition-all ease-linear duration-200 hover:scale-105 font-medium'>Add</button>
                            </div>

                    }
                    <div className=' mt-3'>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {select.map((item, index) => (
                                            <Draggable key={item} draggableId={item} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    // style={getItemStyle(
                                                    //     snapshot.isDragging,
                                                    //     provided.draggableProps.style
                                                    // )}
                                                    >
                                                        <div className={` font-medium flex justify-between items-center text-gray-700 text-sm py-2 px-1 border-b ${snapshot.isDragging ? "bg-gray-100" : ""} `}>
                                                            <p>{item}</p>
                                                            <div onClick={() => deltetSelect(item)} className=' cursor-pointer flex justify-center items-center'>
                                                                <button className='shadow shadow-red-300 py-[2px] px-1 rounded text-red-700 transition-all ease-linear duration-200 hover:scale-105 font-medium'>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SelectComponent