import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Host } from '../../Data';
import ObjectDataFormat from './ObjectDataFormat';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpdateDataFormat from './UpdateDataFormat';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

function DataFormatComponent({ currantCompany, setCurrantCompany, setIsCreateDataFormateOpen }) {

    const location = useLocation();
    let companyId = location.pathname.split('/')[2];
    const user = useSelector(state => state.User.User);
    const [formateKeys, setFormateKeys] = useState([]);
    const [companyDataFormatArray, setCompanyDataFormatArray] = useState([]);
    const [isUpdateModeOpen, setIsUpdateModeOpen] = useState("");
    const [oldPropertyName, setOldPropertyName] = useState("")

    const toggleUpdateMode = (oldName) => {
        setIsUpdateModeOpen(!isUpdateModeOpen)
        setOldPropertyName(oldName)
    }
    const setCompany = async () => {
        try {
            const response = await axios.get(`${Host}/api/companies/${companyId}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            setCurrantCompany(response.data.data);
        } catch (error) {
            console.log(error)
        }
    }
    const deleteFormat = async (oldName) => {
        const confirmDelete = window.confirm("Are you sure. You want to delete the data format.")
        if (!confirmDelete) {
            return
        }
        try {
            await axios.delete(`${Host}/api/companies/${companyId}/delete-format`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`,
                },
                data: {
                    deleteFormat: oldName
                }
            });

            setCompany();
            toast.success("deleted successfully.")
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.error)
        }
    }


    const getCompany = async () => {
        if (currantCompany.dataFormat) {
            const createArrayFromObject = (object) => {
                return Object.keys(object).map(key => object[key]);
            }
            setFormateKeys(Object.keys(currantCompany.dataFormat));
            setCompanyDataFormatArray(createArrayFromObject(currantCompany.dataFormat));
        } else {
            setFormateKeys([])
            setCompanyDataFormatArray([])
        }
    }

    // console.log({ formateKeys, companyDataFormatArray })

    useEffect(() => {
        getCompany();
    }, [currantCompany]);


    return (
        <div className='  bg-white shadow shadow-gray-300 rounded w-full p-5 relative'>
            <p className=' text-center font-semibold'>Data Format</p>
            <div className=' flex justify-end mt-1 sm:mt-0'>
                <button onClick={() => { setIsCreateDataFormateOpen(true) }} className='rounded hover:scale-105 transition-all duration-200 ease-in text-purple-600 py-1 px-2 shadow shadow-purple-300 sm:mb-0 mb-3 sm:mr-3'>Add Format</button>
            </div>
            <div>
                {
                    formateKeys.length > 0 && formateKeys.map((key, index) => {
                        return (
                            <div key={index} className=' my-1 sm:text-sm text-[12px] flex justify-between items-center'>
                                <div>
                                    <p className='font-semibold'>{key} : {"{"}</p>
                                    {
                                        companyDataFormatArray.length > 0 && companyDataFormatArray.map((item, i) => {

                                            if (index === i && item.type === "String") {
                                                return (
                                                    <div key={i} className=" my-1 ml-5">
                                                        <p className=' my-[2px] text-gray-600'> <span className=' font-semibold text-gray-800'>Type</span> : String</p>
                                                        <p className='text-gray-600'> <span className=' font-semibold text-gray-800 '>Value</span> : {item.value}</p>
                                                    </div>
                                                )
                                            }

                                            if (index === i && item.type === "Object") {
                                                return (
                                                    <div key={i} className=" my-1 ml-5">
                                                        <p className=' my-[2px] text-gray-600'> <span className=' font-semibold text-gray-800'>Type</span> : Object</p>
                                                        <div className='text-gray-600'> <span className=' font-semibold text-gray-800'>Value</span> : {"{"} <ObjectDataFormat value={item.value} />
                                                            <p>{"}"}</p>

                                                        </div>
                                                    </div>
                                                )
                                            }

                                            if (index === i && item.type === "Array") {

                                                return (
                                                    <div key={i} className=" my-1 ml-5">
                                                        <p className=' my-[2px] text-gray-600'> <span className=' font-semibold text-gray-800'>Type</span> : Array</p>
                                                        <p className='text-gray-600'> <span className=' font-semibold text-gray-800'>Value</span> : {item.value}</p>
                                                    </div>
                                                )
                                            }

                                            if (index === i && item.type === "ArrayObject") {

                                                return (
                                                    <div key={i} className=" my-1 ml-5">
                                                        <p className=' my-[2px] text-gray-600'> <span className=' font-semibold text-gray-800'>Type</span> : ArrayObject</p>
                                                        <div className='text-gray-600'> <span className=' font-semibold text-gray-800'>Value</span> : {"{"} <ObjectDataFormat value={item.value} />
                                                            <p>{"}"}</p>

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })

                                    }
                                    <p className='font-semibold'>{"}"}</p>
                                </div>
                                <div className=' flex items-center'>
                                    <AiFillEdit onClick={() => toggleUpdateMode(key)} className=" text-green-500 text-lg cursor-pointer" />
                                    {/* <button onClick={() => toggleUpdateMode(key)} className=' border-[1px] rounded p-1 text-blue-500 shadow shadow-blue-200 hover:scale-110 hover:text-blue-600 transition-all duration-100 ease-in'>Edit</button> */}
                                    <AiFillDelete onClick={() => { deleteFormat(key) }} className=" text-red-500 text-lg cursor-pointer ml-2" />
                                    {/* <button onClick={() => { deleteFormat(key) }} className=' border-[1] rounded p-1 text-red-500 shadow shadow-red-300 ml-2 hover:scale-110 hover:text-red-600 transition-all duration-100 ease-in'>Delete</button> */}
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            {
                isUpdateModeOpen &&
                <UpdateDataFormat setIsUpdateModeOpen={setIsUpdateModeOpen} currantCompany={currantCompany} setCurrantCompany={setCurrantCompany} oldPropertyName={oldPropertyName} setCompany={setCompany} />
            }
        </div>
    )
}

export default DataFormatComponent