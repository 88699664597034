import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Host } from '../../Data';
import CompanyFilterField from './CompanyFilterField';
import UpdateCompanyFilterField from './UpdateCompanyFilterField';

function ConditionComponent({ toggleCreateConditionMode, optionsArray, dataset, setDataset, conditionalTimesOptions, fatchdata }) {
    const [filtersData, setFiltersData] = useState({});
    const [addConditionModeOpen, setConditionModeOpen] = useState(false);
    const companyUser = useSelector((state) => state.CompanyUser.CompanyUser);
    const [editModeOpen, setEditModeOpen] = useState(false);
    const [currentCondition, setCurrentCondition] = useState(null)

    const toggleEditMode = (condition) => {
        setEditModeOpen(!editModeOpen)
        setCurrentCondition(condition)
    }
    const toggleAddMode = () => {
        setConditionModeOpen(!addConditionModeOpen)
    }
    // console.log(dataset)
    const saveConditions = async () => {
        try {
            const res = await axios.post(`${Host}/api/datasets/${dataset._id}/conditions`, filtersData, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            setDataset({ ...dataset, conditions: res.data.data.conditions })
            toggleAddMode()
            toast.success("condition added")
        } catch (error) {
            toast.error("something went wrong")
            console.log(error)
        }
    }

    const updateConditions = async () => {
        try {
            const res = await axios.put(`${Host}/api/datasets/${dataset._id}/conditions/${currentCondition._id}`, filtersData, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            // console.log(res.data)
            setDataset({ ...dataset, conditions: res.data.data.conditions })
            toggleEditMode()
            setFiltersData({})
            toast.success("condition update success")
        } catch (error) {
            // toast.error(error.response.data.error)
            console.log(error)
        }
    }
    // console.log(filtersData)
    const deleteCondition = async (id) => {
        try {
            const res = await axios.delete(`${Host}/api/datasets/${dataset._id}/conditions/${id}`, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            const newDataset = { ...dataset }
            newDataset.conditions = newDataset.conditions.filter((condition) => condition._id.toString() !== id.toString())
            setDataset(newDataset)
            toast.success("condition delete successfully")
        } catch (error) {
            console.log(error)
            toast.error(error.response.data.error)
        }
    }


    // console.log(dataset.conditions)
    return (
        <div className=' fixed top-0 left-0 z-50 w-full h-screen p-5 bg-black bg-opacity-25 flex justify-center'>
            <div className=' min-w-[900px]'>
                <div className=' w-full bg-white sm:mt-32 rounded shadow p-5 relative'>
                    <div onClick={toggleCreateConditionMode} className=' cursor-pointer flex justify-center items-center absolute top-3 right-3'>
                        <button className='shadow shadow-red-300 py-[2px] px-1 rounded text-red-700 transition-all ease-linear duration-200 hover:scale-105 font-medium'>Close</button>
                    </div>
                    <div className=' w-full'>
                        <p className=' text-center font-semibold text-lg text-gray-800'>Condition Details</p>
                        {
                            addConditionModeOpen ?
                                <div className=' w-full mt-2'>
                                    <div className=' flex justify-end'>
                                        <button onClick={saveConditions} className=' shadow shadow-blue-300 rounded px-1 py-[2px] text-blue-700 hover:scale-105 transition-all font-medium ease-in mr-2'>Save</button>
                                        <button onClick={toggleAddMode} className=' shadow shadow-red-300 rounded px-1 py-[2px] text-red-700 hover:scale-105 transition-all font-medium ease-in'>Close</button>
                                    </div>
                                    <CompanyFilterField optionsArray={optionsArray} setFiltersData={setFiltersData} dataset={dataset} conditionalTimesOptions={conditionalTimesOptions} />
                                </div>
                                :
                                <div className=' flex justify-end mt-2'>
                                    <button onClick={toggleAddMode} className=' shadow shadow-blue-300 rounded px-1 py-[2px] text-blue-700 hover:scale-105 transition-all font-medium ease-in'>Add</button>
                                </div>

                        }
                        <table className=' w-full mt-3'>
                            <thead>
                                <tr className=' bg-gray-100 text-sm'>
                                    <th className=' text-left text-gray-700 font-medium p-1'>Key</th>
                                    <th className=' text-gray-700 font-medium p-1'>Type</th>
                                    <th className='  text-gray-700 font-medium p-1'>Condition</th>
                                    <th className=' text-right text-gray-700 font-medium p-1'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataset.conditions.map((condition, index) => {

                                        if (editModeOpen && currentCondition._id === condition._id) {
                                            return (
                                                <tr key={index}>
                                                    <td className=' text-gray-700 text-sm border-t p-1' colSpan={3}>
                                                        <UpdateCompanyFilterField optionsArray={optionsArray} filtersData={currentCondition} setFiltersData={setFiltersData} dataset={dataset} conditionalTimesOptions={conditionalTimesOptions} />
                                                    </td>
                                                    <td className='text-right text-gray-700 text-sm border-t p-1'>
                                                        <button onClick={updateConditions} className=' text-sm shadow shadow-green-300 rounded px-1 py-[2px] text-green-700 hover:scale-105 transition-all font-medium ease-in'>Save</button>
                                                        <button onClick={() => toggleEditMode(condition)} className=' text-sm shadow shadow-red-300 rounded px-1 py-[2px] text-red-700 hover:scale-105 transition-all font-medium ease-in ml-1'>Close</button>
                                                    </td>
                                                </tr>
                                            )
                                        } else {
                                            return (
                                                <tr key={index}>
                                                    <td className=' text-gray-700 text-sm border-t p-1'>{condition.key}</td>
                                                    <td className={`text-gray-700 text-sm border-t p-1 text-center`}>{condition.type}</td>
                                                    <td className=' text-gray-700 text-sm border-t p-1 text-center'>
                                                        {condition.type === "range" && <span>{condition.value.start} - <span>{condition.value.end}</span></span>}
                                                        {condition.type === "dynamic-date-range" && <span>{condition.value.start} - <span>{condition.value.end}</span></span>}
                                                        {condition.type === "select" && <span>{condition.value}</span>}
                                                        {condition.type === "multiselect" &&
                                                            <div>
                                                                {condition.value.map((val, ind) => {
                                                                    return (
                                                                        <span key={ind}>{ind !== 0 && ", "}{val}</span>
                                                                    )
                                                                })}
                                                            </div>
                                                        }
                                                        {condition.type === "condition" && <span> {condition.value.condition} {condition.value.value}</span>}
                                                        {condition.type === "conditional-times" && <span>{condition.value.condition} {condition.value.value}</span>}
                                                    </td>

                                                    <td className='text-right text-gray-700 text-sm border-t p-1'>
                                                        <button onClick={() => toggleEditMode(condition)} className=' text-sm shadow shadow-green-300 rounded px-1 py-[2px] text-green-700 hover:scale-105 transition-all font-medium ease-in'>Edit</button>
                                                        <button onClick={() => deleteCondition(condition._id)} className=' ml-1 text-sm shadow shadow-red-300 rounded px-1 py-[2px] text-red-700 hover:scale-105 transition-all font-medium ease-in'>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    })
                                }

                            </tbody>
                        </table>
                        <ToastContainer
                            position="top-right"
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            className="w-72"
                        />
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ConditionComponent