import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Host } from '../../Data';
import { AllCompanyFatchSuccess, UpdateCompanySuccess, DeleteCompanySuccess } from "../../Redux/Companies_slice";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiFillCloseCircle, AiFillDelete, AiFillEdit, AiFillSave } from 'react-icons/ai';
import { HiViewGrid } from "react-icons/hi"
import { FaDraftingCompass } from 'react-icons/fa';

function AllCompanies() {
    const AllCompanies = useSelector(state => state.Companies.Companies);
    const [isUpdateModeOpen, setIsUpdateModeOpen] = useState(false);
    const [isAddModeOpen, setIsAddModeOpen] = useState(false);
    const [currantCompany, setCurrantCompany] = useState(null);
    const User = useSelector(state => state.User.User);
    const dispatch = useDispatch()
    const [companyName, setCompanyName] = useState('');
    const [companyUserEmail, setCompanyUserEmail] = useState('');
    const [companyUserPassword, setCompanyUserPassword] = useState('');
    const [passwordUpdateMode, setPasswordUpdateMode] = useState(false)
    const [userPassword, setUserPassword] = useState("")


    const togglePasswordUpdateMode = (company) => {
        setPasswordUpdateMode(!passwordUpdateMode)
        setCurrantCompany(company)
        setUserPassword("")
        setCompanyUserPassword("")

    }

    const toggleUpdateMode = (company) => {
        setIsUpdateModeOpen(!isUpdateModeOpen);
        if (company) {
            setCurrantCompany(company);
            setCompanyName(company.companyName);
            setCompanyUserEmail(company.email)
        }
        if (isAddModeOpen) {
            setIsAddModeOpen(false)
        }
    }

    const updateCompanyName = async (id) => {
        if (!companyName) {
            toast.error('Please Enter Company Name')
            return
        }
        const data = {
            companyName,
            email: companyUserEmail
        }
        if (companyUserPassword) {
            data.password = companyUserPassword
        }
        try {
            const res = await axios.put(`${Host}/api/companies/${id}`, data, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })
            dispatch(UpdateCompanySuccess(res.data.data));
            toast.success('Company Updated Successfully')
            toggleUpdateMode(res.data.data)
        } catch (error) {
            toast.error('Something went wrong')
            console.log(error);
        }
    }

    const updateCompanyPassword = async (id) => {
        const data = {
            newPassword: companyUserPassword,
            userPassword: userPassword,
        }
        try {
            const res = await axios.put(`${Host}/api/companies/${id}/password`, data, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })
            toast.success(res.data.message)
            setUserPassword("")
            setCompanyUserPassword("")
        } catch (error) {
            toast.error(error.response.data.error)
            console.log(error);
        }
    }


    const deleteCompany = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete the company. All the company data will be lost.")
        if (!confirmDelete) {
            return
        }
        try {
            await axios.delete(`${Host}/api/companies/${id}`, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            })
            dispatch(DeleteCompanySuccess(id));
            toast.success('Company Deleted Successfully')
        } catch (err) {
            toast.error('Something went wrong')
        }
    }

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await axios.get(`${Host}/api/companies`, {
                    headers: {
                        'Authorization': `Bearer ${User.token}`
                    }
                })
                dispatch(AllCompanyFatchSuccess(response.data.data));
            } catch (err) {
                console.log(err);
            }
        }
        fetchCompanies();
    }, [User])



    return (

        <div className='w-full lg:overflow-hidde'>
            <div className=' w-full flex justify-center mt-10'>
                <div className=' w-[70%]  bg-white shadow p-5 rounded'>
                    <div className=''>
                        <p className='font-semibold text-center sm:text-lg'>Companies</p>
                    </div>
                    {
                        isUpdateModeOpen &&
                        <div className=' w-full h-screen fixed top-0 left-0 z-50 bg-black bg-opacity-25 p-5 flex justify-center'>
                            <div className=' w-full sm:w-[500px] mt-20 sm:mt-32'>
                                <div className=' rounded bg-white shadow p-5 relative'>
                                    <p className=' text-gray-700 font-medium text-center text-lg'>Update Company</p>
                                    <div className=' absolute top-5 right-5'>
                                        <AiFillCloseCircle onClick={() => setIsUpdateModeOpen(false)} className='text-red-500 cursor-pointer text-lg sm:text-3xl ml-2' />
                                    </div>
                                    <div className=' my-2'>
                                        <label htmlFor="" className=' font-medium text-gray-700 text-sm sm:text-base'>Company Name : </label>
                                        <input className=' w-full mt-1 focus:outline-none border rounded mr-5 py-[6px] px-1' placeholder='Company Name' value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                    </div>
                                    <div className=' mb-2'>
                                        <label htmlFor="" className=' font-medium text-gray-700 text-sm sm:text-base'>Email : </label>
                                        <input className=' w-full mt-1 focus:outline-none border rounded mr-5 py-[6px] px-1' placeholder='Email' value={companyUserEmail} onChange={(e) => setCompanyUserEmail(e.target.value)} />
                                    </div>
                                    <div className=' w-full mt-3'>
                                        <button onClick={() => { updateCompanyName(currantCompany._id) }} className=' w-full bg-blue-500 hover:bg-blue-700 py-2 text-white font-medium rounded'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        passwordUpdateMode &&
                        <div className=' w-full h-screen fixed top-0 left-0 z-50 bg-black bg-opacity-25 p-5 flex justify-center'>
                            <div className=' w-full sm:w-[500px] mt-20 sm:mt-32'>
                                <div className=' rounded bg-white shadow p-5 relative'>
                                    <p className=' text-gray-700 font-medium text-center text-lg'>Update Company</p>
                                    <div className=' absolute top-5 right-5'>
                                        <AiFillCloseCircle onClick={() => setPasswordUpdateMode(false)} className='text-red-500 cursor-pointer text-lg sm:text-3xl ml-2' />
                                    </div>
                                    <div className=' mb-2'>
                                        <label htmlFor="" className=' font-medium text-gray-700 text-sm sm:text-base'>New Password : </label>
                                        <input className=' w-full mt-1 focus:outline-none border rounded mr-5 py-[6px] px-1' type="password" placeholder='Password' value={companyUserPassword} onChange={(e) => setCompanyUserPassword(e.target.value)} />
                                    </div>
                                    <div className=' mb-2'>
                                        <label htmlFor="" className=' font-medium text-gray-700 text-sm sm:text-base'>User Password : </label>
                                        <input className=' w-full mt-1 focus:outline-none border rounded mr-5 py-[6px] px-1' type="password" placeholder='Password' value={userPassword} onChange={(e) => setUserPassword(e.target.value)} />
                                    </div>
                                    <div className=' w-full mt-3'>
                                        <button onClick={() => { updateCompanyPassword(currantCompany._id) }} className=' w-full bg-blue-500 hover:bg-blue-700 py-2 text-white font-medium rounded'>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <table className=' w-full mt-3'>
                        <tbody>
                            <tr className=' text-sm font-medium border-b'>
                                <td className=' pb-2'>Name</td>
                                <td className=' pb-2 text-right'>Actions</td>
                            </tr>

                            {
                                AllCompanies.length > 0 && AllCompanies.map((company, index) => {
                                    return (
                                        <tr key={index} className=' border-b text-sm font-medium hover:bg-gray-100'>
                                            <td className=' py-2 px-[2px]'>
                                                <div className=' text-gray-700 font-semibold'>
                                                    <span className=' mb-2'>{company.companyName}</span>
                                                </div>
                                            </td>

                                            <td className=' py-2 px-[2px] text-right'>
                                                <div className=' flex justify-end'>
                                                    {/* view company details */}
                                                    <Link to={`/singleCompany/${company._id}`}>
                                                        <HiViewGrid className=" text-blue-500 text-lg" />
                                                    </Link>
                                                    <FaDraftingCompass onClick={() => togglePasswordUpdateMode(company)} className=' ml-2 cursor-pointer text-green-400' />
                                                    {/* edit company */}
                                                    <AiFillEdit onClick={() => toggleUpdateMode(company)} className=" text-green-500 text-lg cursor-pointer ml-2" />
                                                    {/* delete company */}
                                                    <AiFillDelete onClick={() => { deleteCompany(company._id) }} className=" text-red-500 text-lg cursor-pointer ml-2" />

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>


                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default AllCompanies