import React, { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { Host } from "../../Data"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

function BulkDataUpload() {
    const companyUser = useSelector((state) => state.CompanyUser.CompanyUser)
    const [file, setFile] = useState(null)
    const [options, setOptions] = useState([])
    const [databaseCompareField, setDatabaseCompareField] = useState("")
    const [datasetCompareField, setDatasetCompareField] = useState("")

    const upladFile = async () => {
        const formData = new FormData()
        formData.append("excel", file);
        formData.append("fileName", file.name);
        formData.append("databaseCompareField", databaseCompareField)
        formData.append("datasetCompareField", datasetCompareField)
        try {
            const res = await axios.post(`${Host}/api/clients/bulk-upload`, formData, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            toast.success("client data updated")
            console.log(res.data)
        } catch (error) {
            toast.error(error.response.data.error);
        }
    }

    useEffect(() => {
        let options = Object.keys(companyUser.dataFormat)
        options = options.filter((key, index) => companyUser.dataFormat[key].type === "String")
        let keys = []
        for (let i of companyUser.keys) {
            if (i.type === "date") {
                keys.push(i.keyName)
            }
        }
        let filteredKeys = []
        for (let i of options) {
            for (let j of keys) {
                if (companyUser.dataFormat[i].value === j && !filteredKeys.includes(j)) {
                    filteredKeys.push(j)
                }
            }
        }
        setOptions(filteredKeys)
    }, [])


    return (
        <div className=' w-full p-5'>
            <div className=' w-full flex justify-center my-5 sm:mt-10'>
                <div className='min-h-60 min-w-60 bg-white  shadow-custom p-8 rounded'>
                    <h1 className=' text-lg font-medium text-center mb-5'>Upload Client Data</h1>
                    {
                        file && databaseCompareField && datasetCompareField ?
                            <div className=' w-full h-full'>
                                <div className=' flex justify-center'>
                                    <button onClick={upladFile} className=' bg-[#e618d4] rounded p-1 text-white font-medium'>Upload File</button>
                                </div>
                            </div>
                            :
                            <div className=' w-full h-full'>
                                <div>
                                    <div className=' w-full h-full pb-3'>
                                        <label htmlFor="file" className='font-medium text-sm text-gray-700 w-full'>Database Compare Field</label>
                                        <select value={databaseCompareField} onChange={(e) => setDatabaseCompareField(e.target.value)} name="" id="" className=' text-sm w-full border p-1 rounded focus:outline-none'>
                                            <option value="" className=''>Select Option</option>
                                            {
                                                options.map((option, index) => {
                                                    return (
                                                        <option value={option} key={index} className="capitalize">{option}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className=' w-full h-full pb-3'>
                                        <label htmlFor="file" className='font-medium text-sm text-gray-700 w-full'>Dataset Compare Field</label>
                                        <select value={datasetCompareField} onChange={(e) => setDatasetCompareField(e.target.value)} name="" id="" className=' text-sm w-full border p-1 rounded focus:outline-none'>
                                            <option value="">Select Option</option>
                                            {
                                                options.map((option, index) => {
                                                    return (
                                                        <option value={option} key={index} className="capitalize">{option}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className=' mt-2 flex justify-center'>
                                    <input onChange={(e) => { setFile(e.target.files[0]) }} type="file" id='file' className=' hidden' />
                                    <label htmlFor="file" className=' flex justify-center items-center font-medium cursor-pointer bg-gray-100 w-full h-32 rounded border'>Select File</label>
                                </div>

                            </div>
                    }
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default BulkDataUpload