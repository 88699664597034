import React from 'react'
import Header from './UserHeader'
import Sidebar from "./UserSidebar"

function UserLayout({ children }) {
    return (
        <div className=' bg-gray-100 min-h-screen w-full'>
            <Header />
            <div className=' w-full flex'>
                <Sidebar Home />
                <div className=' pt-14 sm:pt-16 px-2 sm:px-5  w-[calc(100%-60px)] sm:w-[calc(100%-200px)] overflow-hidden'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default UserLayout