import React, { useState } from 'react'
import { Host } from '../../Data';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addUserSuccess } from '../../Redux/Allusers_slice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddUser() {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [phone, setPhone] = useState('');
    const Admin = useSelector(state => state.Admin.Admin);
    const dispatch = useDispatch();

    const SubmitData = async (e) => {

        e.preventDefault();

        if (!firstName || !lastName || !email || !password || !confirmPassword || !whatsappNumber || !phone) {

            toast.error('Please fill all the fields');
            return;
        }

        if (password !== confirmPassword) {

            toast.error('password and confirm password not match');
            return;
        }

        const userData = {
            firstName,
            lastName,
            email,
            password,
            whatsappNumber,
            phone
        }

        try {

            const response = await axios.post(`${Host}/api/admins/users`, userData, {
                headers: {
                    'Authorization': `Bearer ${Admin.token}`
                }
            });

            setFirstName('');
            setLastName('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            setWhatsappNumber('');
            setPhone('');
            dispatch(addUserSuccess(response.data));
            toast.success('User added successfully');

        } catch (err) {

            toast.error(err.message)
        }
    };


    return (
        <div className=' w-full flex justify-center pt-5 md:pt-10 sm:px-5 md:px-10 lg:px-28 xl:px-40'>
            <div className=' w-full sm:w-[700px]  bg-white rounded-lg shadow shadow-gray-200 flex justify-center overflow-hidden'>
                <div className=' w-full py-2 sm:py-5'>
                    <h2 className=' text-center text-base sm:text-xl md:text-2xl pt-5 font-semibold'>Create User</h2>
                    <form className=' w-full grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3 py-3 sm:py-5 md:py-10 px-3 sm:px-5 md:px-10' onSubmit={SubmitData}>
                        <div className=' '>
                            <label htmlFor="" className=' font-medium text-gray-700 text-sm sm:text-base'>First Name</label>
                            <input onChange={(e) => { setFirstName(e.target.value) }} value={firstName} type="text" placeholder='Frist name' required={true} className=' w-full border rounded sm:p-2 p-1 mt-1  focus:outline-none' />
                        </div>
                        <div className=' '>
                            <label htmlFor="" className=' font-medium text-gray-700 text-sm sm:text-base'>Last Name</label>
                            <input onChange={(e) => { setLastName(e.target.value) }} value={lastName} type="text" placeholder='Last name' required={true} className=' w-full border rounded sm:p-2 p-1 mt-1  focus:outline-none' />
                        </div>
                        <div className=' '>
                            <label htmlFor="" className=' font-medium text-gray-700 text-sm sm:text-base'>Phone Number</label>
                            <input onChange={(e) => { setPhone(e.target.value) }} value={phone} type="number" placeholder='Phone number' required={true} className=' w-full border rounded sm:p-2 p-1 mt-1  focus:outline-none' />
                        </div>
                        <div className=' '>
                            <label htmlFor="" className=' font-medium text-gray-700 text-sm sm:text-base'>Email</label>
                            <input onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder='Email' required={true} className=' w-full border rounded sm:p-2 p-1 mt-1  focus:outline-none' />
                        </div>
                        <div className=' '>
                            <label htmlFor="" className=' font-medium text-gray-700 text-sm sm:text-base'>Password</label>
                            <input onChange={(e) => { setPassword(e.target.value) }} value={password} type="password" placeholder='Password' required={true} className=' w-full border rounded sm:p-2 p-1 mt-1  focus:outline-none' />
                        </div>
                        <div className=' '>
                            <label htmlFor="" className=' font-medium text-gray-700 text-sm sm:text-base'>Confirm Password</label>
                            <input onChange={(e) => { setConfirmPassword(e.target.value) }} value={confirmPassword} type="password" required={true} placeholder='Confirm Password' className=' w-full border rounded sm:p-2 p-1 mt-1  focus:outline-none' />
                        </div>
                        <div className=' '>
                            <label htmlFor="" className=' font-medium text-gray-700 text-sm sm:text-base'>Whatsapp Number</label>
                            <input onChange={(e) => { setWhatsappNumber(e.target.value) }} value={whatsappNumber} type="text" required={true} placeholder='Whatsapp Number' className=' w-full border rounded sm:p-2 p-1 mt-1  focus:outline-none' />
                        </div>
                        <div className=' w-full   flex justify-center mt-3 sm:mt-5 md:col-span-2'>
                            <button type='submit' className=' border-[1px] rounded px-2 py-1 bg-blue-500 text-white hover:bg-blue-600 transition-all duration-100 ease-in'>Create</button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default AddUser