import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Layout from "./AdminLayout";


function AdminPrivateRoute({ children }) {
    const Admin = useSelector(state => state.Admin.Admin);
    return (
        Admin ? <Layout>{children}</Layout> : <Navigate to="/admin/login" />
    )
}

export default AdminPrivateRoute