import React, { useEffect, useState } from 'react'
import { Host } from '../../Data';
import axios from 'axios';
import { useSelector } from 'react-redux';

function UpdateCompanyFilterField({ optionsArray, filtersData, setFiltersData, dataset, conditionalTimesOptions }) {
    const [selectedFilterKey, setSelectedFilterKey] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const [filterOption, setFilterOption] = useState(null);
    const [filterType, setFilterType] = useState(null);
    const [startRangeValue, setStartRangeValue] = useState(null);
    const [endRangeValue, setEndRangeValue] = useState(null);
    const [uniqueValues, setUniqueValues] = useState([]);
    const companyUser = useSelector((state) => state.CompanyUser.CompanyUser)

    useEffect(() => {
        if (filtersData) {
            setSelectedFilterKey(filtersData.key)
            setFilterType(filtersData.type)
            setFiltersData({ key: filtersData.key, type: filtersData.type })
            getUniqueValues(filtersData.key)
            if (filtersData.type === "select") {
                setFilterValue(filtersData.value)
            } else if (filtersData.type === "range" || filtersData.type === "dynamic-date-range") {
                setStartRangeValue(filtersData.value.start)
                setEndRangeValue(filtersData.value.end)
            } else if (filtersData.type === "condition" || filtersData.type === "conditional-times") {
                setFilterValue({ value: filtersData.value.value })
                setFilterOption({ condition: filtersData.value.condition })
            } else if (filtersData.type === "multiselect") {
                setFilterValue(filtersData.value)
            }
        }
    }, [filtersData])

    const getUniqueValues = async (key) => {
        try {
            const res = await axios.post(`${Host}/api/datasets/${dataset._id}/dropdown`, { field_name: key }, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            });
            setUniqueValues(res.data.data);
        } catch (error) {
            console.log(error)
        }
    }

    const changeFilterKey = async (key) => {
        if (filterType === "multiselect" || filterType === "select") {
            getUniqueValues(key)
        }
        setFiltersData({ key: key, type: filterType, value: filterValue });
        setSelectedFilterKey(key);
    }
    const changeFilterValue = (value, from = null, to = null) => {
        if (filterType === "range" || filterType === "dynamic-date-range") {
            if (from) {
                setStartRangeValue(value);
                setFiltersData({ key: selectedFilterKey, type: filterType, value: { end: endRangeValue, start: value } });
            } else if (to) {
                setEndRangeValue(value);
                setFiltersData({ key: selectedFilterKey, type: filterType, value: { start: startRangeValue, end: value } });
            }
        } else if (filterType === "select") {
            setFilterValue(value);
            setFiltersData({ key: selectedFilterKey, type: filterType, value: value });
        } else if (filterType === "multiselect") {
            console.log(value)
            let values = Array.from(value.target.selectedOptions, (option) => option.value)
            setFilterValue(values);
            setFiltersData({ key: selectedFilterKey, type: filterType, value: values });
        } else if (filterType === "condition" || filterType === "conditional-times") {
            setFilterValue({ ...filterValue, value: value });
            setFiltersData({ key: selectedFilterKey, type: filterType, value: { ...filterValue, value: value } });
        }
    }
    const changeFilterOption = (option) => {
        setFiltersData({ key: selectedFilterKey, type: filterType, value: { ...filterValue, condition: option } });
        setFilterOption(option);
    }
    const changeFilterType = (type) => {
        setFiltersData({ key: selectedFilterKey, type: type, value: filterValue });
        setFilterType(type);
    }


    return (
        <div className=' w-full py-2 flex justify-between' >
            <select value={filterType} onChange={(e) => changeFilterType(e.target.value)} name="" id="" className=' border border-blue-500 rounded py-1 px-2 focus:outline-none'>
                <option value="">Filter Type</option>
                <option value="range">Range</option>
                <option value="dynamic-date-range">Dynamic Date Range</option>
                <option value="select">Select</option>
                <option value="multiselect">Multiselect</option>
                <option value="condition">Condition</option>
                <option value="conditional-times">Conditional Times</option>
            </select>
            {
                filterType && filterType !== "conditional-times" &&
                <select value={selectedFilterKey} onChange={(e) => changeFilterKey(e.target.value)} name="" id="" className=' border border-blue-500 rounded py-1 px-2 focus:outline-none'>
                    <option value="">Select Property</option>
                    {
                        optionsArray.map((key) => {
                            return (
                                <option key={key} value={key}>{key}</option>
                            )
                        })
                    }
                </select>

            }

            {
                filterType && filterType === "conditional-times" &&
                <select onChange={(e) => changeFilterKey(e.target.value)} name="" id="" className=' border border-blue-500 rounded py-1 px-2 focus:outline-none'>
                    <option value="">Select Property</option>
                    {
                        conditionalTimesOptions.map((key) => {
                            return (
                                <option key={key} value={key}>{key}</option>
                            )
                        })
                    }
                </select>
            }
            {
                (filterType === 'range' || filterType === "dynamic-date-range") &&
                <div>
                    <input type="text" value={startRangeValue} onChange={(e) => { setStartRangeValue(e.target.value); changeFilterValue(e.target.value, "from", null) }} placeholder={"From"} className=" border border-blue-500 rounded py-1 px-2 focus:outline-none" />
                    <input type="text" value={endRangeValue} onChange={(e) => { setEndRangeValue(e.target.value); changeFilterValue(e.target.value, null, "to") }} placeholder={"To"} className=" border border-blue-500 rounded py-1 px-2 focus:outline-none ml-3" />
                </div>
            }
            {
                filterType === 'select' && uniqueValues.length > 0 &&
                <select value={filterValue} onChange={(e) => changeFilterValue(e.target.value)} name="" id="" className=' border border-blue-500 rounded py-1 px-2 focus:outline-none'>
                    <option value="">Select Property</option>
                    {
                        uniqueValues.map((key) => {
                            return (
                                <option key={key} value={key}>{key}</option>
                            )
                        })
                    }
                </select>
            }
            {
                filterType === 'multiselect' && uniqueValues.length > 0 &&
                <select multiple={true} value={filterValue} onChange={(e) => changeFilterValue(e)} className=' border border-blue-500 rounded py-1 px-4 focus:outline-none max-h-14 w-32'>
                    {
                        uniqueValues.map((key, index) => {
                            return (
                                <option key={key} value={key}>{key}</option>
                            )
                        })
                    }
                </select>
            }
            {
                selectedFilterKey && (filterType === 'condition' || filterType === "conditional-times") &&
                <input type="text" value={filterValue?.value} onChange={(e) => changeFilterValue(e.target.value)} placeholder={`${"Enter " + selectedFilterKey}`} className=" border border-blue-500 rounded py-1 px-2 focus:outline-none" />
            }
            {
                selectedFilterKey && (filterType === 'condition' || filterType === "conditional-times") &&
                <select value={filterOption?.condition} onChange={(e) => { changeFilterOption(e.target.value) }} name="" id="" className=' border border-blue-500 rounded py-1 px-2 focus:outline-none'>
                    <option value="">Filter Conditions</option>
                    <option value=">">Greater then</option>
                    <option value=">=">Greater then and equal</option>
                    <option value="<">Smaller then</option>
                    <option value="<=">Smaller then and equal</option>
                    <option value="=">Equal</option>
                    <option value="!=">Not Equal</option>
                </select>
            }
        </div>
    )
}

export default UpdateCompanyFilterField