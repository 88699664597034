import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Host } from '../../Data';
import DataFormate from '../Components/DataFormate';
import DataPoint from '../Components/DataPoint';
import PrimaryKeySelect from '../Components/PrimaryKeySelect';
import DataFormatComponent from '../Components/DataFormatComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Keys from '../Components/Keys';

function SingleCompany() {
    const user = useSelector(state => state.User.User);
    const [isDataPointOpen, setIsDataPointOpen] = useState(false);
    const [companyToken, setCompanyToken] = useState('');
    const location = useLocation();
    let companyId = location.pathname.split('/')[2];
    const [currantCompany, setCurrantCompany] = useState(null);
    const [isCreateDataFormateOpen, setIsCreateDataFormateOpen] = useState(false);
    const [isPrimaryKeySectionOpen, setIsPrimaryKeySectionOpen] = useState(false);

    const createCompanyToken = async () => {
        try {
            const response = await axios.post(`${Host}/api/companies/token/${companyId}`, { data: {} }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            setCompanyToken(response.data.data.token);
        } catch (error) {
            console.log(error.response.data)
        }
    }

    const getCompany = async () => {
        const response = await axios.get(`${Host}/api/companies/${companyId}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
        setCurrantCompany(response.data.data)
    }

    useEffect(() => {
        getCompany();
    }, []);


    return (

        <div className=' w-full pr-5 overflow-hidden pb-5'>
            {
                currantCompany &&

                <div className=' w-full'>
                    <div className='w-full flex justify-between mt-5 px-1'>
                        <button onClick={() => { setIsPrimaryKeySectionOpen(true) }} className='rounded hover:scale-105 transition-all text-sm sm:text-base duration-200 ease-in text-purple-600 py-[2px] sm:py-1 px-1 sm:px-2 shadow shadow-purple-300 sm:mb-0 mb-3 sm:mr-3'>Select Primary Key</button>
                        <p className=' text-base sm:text-lg font-semibold text-center'> {currantCompany.companyName}</p>
                        <button onClick={() => { setIsDataPointOpen(true); createCompanyToken() }} className=' rounded hover:scale-105 transition-all text-sm sm:text-base duration-200 ease-in text-blue-600 py-[2px] sm:py-1 px-1 sm:px-2 shadow shadow-blue-300'>Get Company Token</button>
                    </div>
                </div>
            }
            <div className=' w-full grid grid-cols-1 lg:grid-cols-2 gap-5 mt-3 sm:mt-5'>
                {
                    currantCompany &&
                    <Keys getCompany={getCompany} currantCompany={currantCompany} />
                }
                {
                    currantCompany &&
                    <DataFormatComponent currantCompany={currantCompany} setIsCreateDataFormateOpen={setIsCreateDataFormateOpen} setCurrantCompany={setCurrantCompany} />
                }
            </div>
            {
                isDataPointOpen &&
                <DataPoint setIsDataPointOpen={setIsDataPointOpen} companyToken={companyToken} />
            }
            {
                isCreateDataFormateOpen &&
                <DataFormate setIsCreateDataFormateOpen={setIsCreateDataFormateOpen} companyId={companyId} currantCompany={currantCompany} setCurrantCompany={setCurrantCompany} getCompany={getCompany} />
            }
            {
                isPrimaryKeySectionOpen && currantCompany &&
                <PrimaryKeySelect setIsPrimaryKeySectionOpen={setIsPrimaryKeySectionOpen} setCurrantCompany={setCurrantCompany} company={currantCompany} />
            }
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default SingleCompany