import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Host } from "../../Data"
import { userUpdateSuccess } from '../../Redux/User_slice'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function UserInfoUpdate() {
    const User = useSelector(state => state.User.User);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState("")
    const dispach = useDispatch();


    const UpdateAdminData = async (e) => {
        e.preventDefault()
        const user = {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            email: email,
            whatsappNumber
        }
        try {
            const response = await axios.put(`${Host}/api/users`, user, {
                headers: {
                    'Authorization': `Bearer ${User.token}`
                }
            });

            dispach(userUpdateSuccess(response.data.data));
            toast.success('UserProfile updated successfully');
        } catch (error) {
            toast.error(error.response.data.error);
        }
    }

    return (

        <div className='w-full pt-5 md:pt-10 sm:px-10 md:px-16 lg:px-20 xl:px-32'>
            <div className='w-full flex justify-center pr-5'>
                <div className=' w-[50%] bg-white rounded p-5 sm:p-10 relative'>
                    <p className=' text-lg sm:text-xl md:text-center font-semibold mb-5'>User Details</p>
                    <div className=' flex'>
                        <div className=' w-full'>
                            <form onSubmit={UpdateAdminData} className=' w-full'>
                                <div className=' w-full'>
                                    <label htmlFor="">First Name</label>
                                    <input id='' onChange={(e) => { setFirstName(e.target.value) }} value={firstName} type="text" placeholder='Frist name' required={true} className='inputFild focus:outline-none' />
                                </div>
                                <div className=' w-full'>
                                    <label htmlFor="">Last Name</label>
                                    <input onChange={(e) => { setLastName(e.target.value) }} value={lastName} type="text" placeholder='Last name' required={true} className='inputFild focus:outline-none' />
                                </div>
                                <div className=' w-full'>
                                    <label htmlFor="">Phone Number</label>
                                    <input onChange={(e) => { setPhone(e.target.value) }} value={phone} type="number" placeholder='Phone number' required={true} className='inputFild focus:outline-none' />
                                </div>
                                <div className=' w-full'>
                                    <label htmlFor="">Email</label>
                                    <input onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" placeholder='Email' required={true} className='inputFild focus:outline-none' />
                                </div>
                                <div className=' w-full'>
                                    <label htmlFor="">WhatsappNumber</label>
                                    <input onChange={(e) => { setWhatsappNumber(e.target.value) }} value={whatsappNumber} type="number" placeholder='whatsappNumber' required={true} className='inputFild focus:outline-none' />
                                </div>
                                <div className=' w-full flex justify-center mt-5'>
                                    <button type='submit' className='w-full border-[1px] rounded p-2 bg-blue-500 text-white hover:bg-blue-600 transition-all duration-100 ease-in'>Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default UserInfoUpdate