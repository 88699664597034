import { configureStore } from '@reduxjs/toolkit';
import UserReducer from './User_slice';
import CompanyReducer from './Companies_slice';
import KeysReducer from './Keys_slice';
import AdminReducer from './Admin_slice';
import AllusersReducer from './Allusers_slice';
import CompanyUser_slice from './CompanyUser_slice';

export const store = configureStore({
    reducer: {
        User: UserReducer,
        Companies: CompanyReducer,
        Keys: KeysReducer,
        Admin: AdminReducer,
        Allusers: AllusersReducer,
        CompanyUser: CompanyUser_slice
    },
})