import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios"
import { Host } from "../../Data"
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateDatasetComponent from '../Components/CreateDatasetComponent';
import { AiFillDelete, AiFillEye } from "react-icons/ai"
// import { ImDownload3 } from "react-icons/im"
// import UpdateDatasetCompnent from '../Components/UpdateDatasetCompnent';

function CompanyDataset() {

    const [datasets, setDatasets] = useState([])
    const companyUser = useSelector((state) => state.CompanyUser.CompanyUser)
    const [pagination, setPagination] = useState(null)
    const [createDatasetModeOpen, setCreateDatasetModeOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const changeDataPerPage = (number) => {
        setLimit(number)
    }
    // console.log(dataPerPage)
    const toggleCreateDatasetMode = () => {
        setCreateDatasetModeOpen(!createDatasetModeOpen)
    }
    const fatchDataset = async function () {
        try {
            const res = await axios.get(`${Host}/api/datasets?page=${page}&limit=${limit}`, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            setDatasets(res.data.data)
            setPagination(res.data.pagination)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fatchDataset()
    }, [page, limit])

    const gotoNext = async () => {
        setPage(pagination.next)
    }

    const gotoPrevious = async () => {
        setPage(pagination.prev)

    }
    const deleteDataset = async (id) => {
        let confirmDelete = window.confirm("Are you sure? you want to delete the dataset .All the conditions will be deleted.")
        if (!confirmDelete) {
            return
        }
        try {
            await axios.delete(`${Host}/api/datasets/${id}`, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            const newDataset = datasets.filter((dataset) => dataset._id.toString() !== id.toString())
            setDatasets(newDataset)
            toast.success("Dataset delete success")
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className=' w-full mt-1 overflow-hidden pb-2 sm:pb-5'>
            {
                createDatasetModeOpen &&
                <CreateDatasetComponent toggleCreateDatasetMode={toggleCreateDatasetMode} fatchDataset={fatchDataset} />
            }

            <div className=' w-full flex justify-center'>
                <div className=' w-full mt-5 bg-white rounded p-5'>
                    <p className=' text-center font-semibold text-xl text-gray-800'>Dataset</p>
                    <div className=' w-full flex justify-between'>
                        <div>
                            <label htmlFor="" className=' font-medium text-sm '>Data per page</label>
                            <select value={limit} onChange={(e) => changeDataPerPage(e.target.value)} name="" id="" className='ml-1 border border-blue-300 p-[2px] rounded focus:outline-none'>
                                <option value="">Select Number</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                                <option value={60}>60</option>
                                <option value={70}>70</option>
                                <option value={80}>80</option>
                                <option value={90}>90</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <button onClick={toggleCreateDatasetMode} className='shadow shadow-blue-300 py-1 px-2 rounded text-blue-700 -translate-y-5 transition-all ease-linear duration-200 hover:scale-105 font-medium'>Create</button>
                    </div>
                    <table className=' w-full mt-3 '>
                        <thead>
                            <tr className=' border-y'>
                                <th className=' font-medium px-[2px] py-1 text-left'>Name</th>
                                <th className=' font-medium px-[2px] py-1'>Condition</th>
                                <th className=' font-medium px-[2px] py-1'>Data</th>
                                <th className=' font-medium px-[2px] py-1'>Status</th>
                                <th className=' font-medium px-[2px] py-1 text-right'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                datasets.map((dataset) => {
                                    return (
                                        <tr className=' border-y text-gray-600'>
                                            <td className='px-[2px] py-1'>{dataset.name}</td>
                                            <td className='px-[2px] py-1 text-center'>{dataset.conditions.length}</td>
                                            <td className='px-[2px] py-1 text-center'>{dataset.count}</td>
                                            <td className='px-[2px] py-1 text-center'>{dataset.status == true ? "True" : "False"}</td>
                                            <td className='px-[2px] py-1 text-right'>
                                                <div className=' flex justify-end'>
                                                    <AiFillDelete onClick={() => deleteDataset(dataset._id)} className=' text-lg cursor-pointer text-red-500 mr-2' />
                                                    <Link to={`/company/silgle-dataset/${dataset._id}`}>
                                                        <AiFillEye className=' text-xl text-green-500 cursor-pointer' />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {
                        pagination && (pagination.next || pagination.prev) &&
                        <div className=' mt-3'>
                            <button onClick={gotoPrevious} className={`  shadow shadow-purple-300 py-1 px-2 rounded text-purple-700 mr-2 transition-all ease-linear duration-200 hover:scale-105 disabled:shadow-none disabled:scale-100`} disabled={!pagination.prev}>Pre</button>
                            <button onClick={gotoNext} disabled={!pagination.next} className={` shadow shadow-purple-300 py-1 px-2 rounded text-purple-700  transition-all ease-linear duration-200 hover:scale-105 disabled:shadow-none disabled:scale-100`}>Next</button>
                        </div>
                    }
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                className="w-72"
            />
        </div>
    )
}

export default CompanyDataset