import React from 'react'
import { AiOutlineHome } from 'react-icons/ai'
import { FaUserCog, FaUserFriends } from 'react-icons/fa'
import MenuItem from './MenuItem'

function AdminSidebar() {
    return (
        <div className={`bg-white pt-24 shadow shadow-gray-300 w-[60px] sm:w-[200px] min-h-screen rounded px-1 sm:px-3`}>
            <ul>
                <MenuItem item={{ name: "Dashboard", link: "/admin/dashboard", icon: AiOutlineHome }} />
                <MenuItem item={{ name: "Users", link: "/admin/users", icon: FaUserFriends, child: [{ name: "Create", link: "/admin/add-user", icon: FaUserCog }, { name: "Users", link: "/admin/all-users", icon: FaUserFriends }] }} />
            </ul>
        </div>
    )
}

export default AdminSidebar