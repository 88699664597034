import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    CompanyUser: localStorage.getItem('CompanyUser') ? JSON.parse(localStorage.getItem('CompanyUser')) : null,
    isLoading: false,
    error: "",
}


export const CompanyUserSlice = createSlice({
    name: 'CompanyUser',
    initialState,
    reducers: {
        CompanyUserLogin: (state, action) => {
            state.CompanyUser = action.payload;
            state.isLoading = false;
            state.error = '';
            localStorage.setItem('CompanyUser', JSON.stringify(action.payload));
        },
        CompanyUserLogout: (state) => {
            state.CompanyUser = null;
            localStorage.removeItem('CompanyUser');
            state.isLoading = false;
            state.error = '';
        }
    },
})

export const { CompanyUserLogin, CompanyUserLogout } = CompanyUserSlice.actions

export default CompanyUserSlice.reducer