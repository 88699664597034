import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    Allusers: [],
}

export const AllusersSlice = createSlice({
    name: 'Allusers',
    initialState,
    reducers: {

        allusersLoadingSuccess: (state, action) => {
            state.Allusers = action.payload;
        },
        addUserSuccess: (state, action) => {
            state.Allusers.push(action.payload);
        },
        deleteUserSuccess: (state, action) => {
            state.Allusers = state.Allusers.filter(user => user.id !== action.payload);
        },
        updateUserSuccess: (state, action) => {
            const index = state.Allusers.findIndex(user => user.id === action.payload.id);
            state.Allusers[index] = action.payload;
        }
    },
})

export const { allusersLoadingSuccess, addUserSuccess, deleteUserSuccess, updateUserSuccess } = AllusersSlice.actions

export default AllusersSlice.reducer