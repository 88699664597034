import React from 'react'
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';


function Profile() {
    const Admin = useSelector(state => state.Admin.Admin);
    return (
        <div className='w-full pt-5 md:pt-10 sm:px-10 md:px-16 lg:px-20 xl:px-32'>
            <div className='w-full flex justify-center pr-5'>
                <div className=' w-[60%] bg-white rounded p-5 relative'>
                    <p className='text-center font-semibold mb-5'>Admin Details</p>
                    <div className=' flex'>
                        <div className=' w-full'>
                            <table className=' w-full'>
                                <tbody>
                                    <tr>
                                        <td className='font-medium pb-2 text-sm'>Name</td>
                                        <td className='font-medium pb-2 text-sm'>Phone</td>
                                        <td className='font-medium pb-2 text-sm'>Email</td>
                                    </tr>
                                    <tr className=' border-y'>
                                        <td className=' py-2 text-sm'>{Admin.firstName + " " + Admin.lastName}</td>
                                        <td className=' py-2 text-sm'>{Admin.phone}</td>
                                        <td className=' py-2 text-sm'>{Admin.email}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile