import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Host } from '../../Data';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiFillCloseCircle } from 'react-icons/ai';

function UpdateDataFormat({ setIsUpdateModeOpen, currantCompany, setCurrantCompany, oldPropertyName, setCompany }) {

    // single property data
    const [propertyName, setPropertyName] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [propertyValue, setPropertyValue] = useState('');
    const user = useSelector(state => state.User.User);
    const [companyKeys, setCompanyKeys] = useState([]);
    const [propartyQuantity, setPropartyQuantity] = useState(0);
    const [showObjectPropartySlection, setShowObjectPropartySlection] = useState(false);
    const [randomArray, setRandomArray] = useState([]);

    useEffect(() => {
        setPropertyType(currantCompany.dataFormat[oldPropertyName].type);
        setPropertyName(oldPropertyName);
        if (currantCompany.dataFormat[oldPropertyName].type === "String" || currantCompany.dataFormat[oldPropertyName].type === "Array") {
            setPropertyValue(currantCompany.dataFormat[oldPropertyName].value)
        } else if (currantCompany.dataFormat[oldPropertyName].type === "Object" || currantCompany.dataFormat[oldPropertyName].type === "ArrayObject") {
            setPropartyQuantity(Object.keys(currantCompany.dataFormat[oldPropertyName].value).length)
            const properties = []
            for (let name in currantCompany.dataFormat[oldPropertyName].value) {
                properties.push({ name, value: currantCompany.dataFormat[oldPropertyName].value[name] })
            }
            const newProperty = properties.map((p, i) => {
                return { ...p, index: i }
            })
            setRandomArray(newProperty);
            setShowObjectPropartySlection(true);
        }
    }, [])

    const clearData = () => {
        setPropertyName('');
        setPropertyType('');
        setPropertyValue('');
        setPropartyQuantity(0);
        setShowObjectPropartySlection(false);
        setRandomArray([]);
    }

    const showObjectPropartySlectionFunc = () => {
        if (propartyQuantity > 0) {
            const newArray = [];
            for (let i = 0; i < propartyQuantity; i++) {
                newArray.push({ name: "", value: "", index: i });
            }
            setRandomArray(newArray);
            setShowObjectPropartySlection(true);
        } else {
            toast.error("Please enter property quantity");
        }
    }

    const changePropartyType = (value) => {
        setPropartyQuantity(0)
        setRandomArray([]);
        setShowObjectPropartySlection(false);
        setPropertyType(value);
    }

    const changeProperty = (value, index, option) => {
        const newRandomArray = [...randomArray]
        if (option === "name") {
            randomArray[index].name = value
        } else if (option === "value") {
            randomArray[index].value = value
        }
        setRandomArray(newRandomArray)
    }


    const UpdateDataFormat = async () => {

        if (propertyType === 'String') {
            const dataFormat = { previous: oldPropertyName }
            dataFormat[`${propertyName}`] = {
                type: propertyType,
                value: propertyValue
            }
            try {
                await axios.put(`${Host}/api/companies/${currantCompany._id}/update-format`, dataFormat, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                });
                clearData();
                toast.success("Data format updated successfully");
                setCompany()
                setIsUpdateModeOpen(false)
            } catch (error) {
                toast.error(error.response.data.error)
            }
        }

        else if (propertyType === 'Object') {
            const dataFormat = {
                [`${propertyName}`]: {
                    type: propertyType,
                    value: {}
                },
                previous: oldPropertyName
            }
            for (let i = 0; i < randomArray.length; i++) {
                dataFormat[`${propertyName}`].value[randomArray[i].name] = randomArray[i].value;
            }
            try {
                const res = await axios.put(`${Host}/api/companies/${currantCompany._id}/update-format`, dataFormat, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`
                    }
                });

                clearData();
                toast.success("Data format updated successfully");
                setCompany()
                setIsUpdateModeOpen(false)
            } catch (error) {
                toast.error(error.response.data.error)
            }
        }

        else if (propertyType === 'Array') {

            const dataFormat = {
                previous: oldPropertyName
            }

            dataFormat[`${propertyName}`] = {
                type: propertyType,
                value: propertyValue
            }

            await axios.put(`${Host}/api/companies/${currantCompany._id}/update-format`, dataFormat, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            clearData();
            toast.success("Data format updated successfully");
            setCompany()
            setIsUpdateModeOpen(false)
        }

        else if (propertyType === 'ArrayObject') {

            const dataFormat = {
                [`${propertyName}`]: {
                    type: propertyType,
                    value: {}
                }
            }

            for (let i = 0; i < randomArray.length; i++) {
                dataFormat[`${propertyName}`].value[randomArray[i].name] = randomArray[i].value;
            }
            await axios.put(`${Host}/api/companies/${currantCompany._id}/update-format`, dataFormat, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            clearData();
            toast.success("Data format updated successfully");
            setCompany()
            setIsUpdateModeOpen(false)
        }
    }


    useEffect(() => {
        if (currantCompany) {
            setCompanyKeys(currantCompany.keys);
        }
    }, [currantCompany])


    return (
        <div className='fixed w-screen h-full overflow-hidden top-0 right-0 bg-black bg-opacity-30 flex justify-center items-center z-50'>
            <div className=' w-full h-full overflow-hidden p-5 flex justify-center items-center box-border'>
                <div className='w-full sm:w-[600px] h-full sm:h-[600px]  overflow-y-scroll scrollbar bg-white shadow shadow-gray-300 rounded relative p-5 sm:p-10'>
                    <AiFillCloseCircle onClick={() => { setIsUpdateModeOpen(false) }} className=' z-[100] text-red-500 absolute top-2 right-2 cursor-pointer text-3xl' />
                    <p className=' text-xl text-center pb-5 font-semibold border-b border-gray-300'>Data formate</p>
                    <div className='mt-5'>
                        <label htmlFor="" className=' block font-semibold mb-2'>Property Name</label>
                        <input type="text" placeholder='Enter property name' value={propertyName} onChange={(e) => setPropertyName(e.target.value)} className="py-1 px-2 rounded focus:outline-none w-full border border-blue-500" />
                    </div>
                    <div className=' mt-3'>
                        <p className='font-semibold mb-3'>Property Type</p>
                        <select name="" id="" className=' focus:outline-none py-1 px-2 rounded w-full border border-blue-500' value={propertyType} onChange={(e) => changePropartyType(e.target.value)}>
                            <option value="">Select Type</option>
                            <option value="String">Simple</option>
                            <option value="Array">Array</option>
                            <option value="Object">Object</option>
                            <option value="ArrayObject">Array Object</option>
                        </select>
                    </div>
                    {
                        propertyType === "String" && companyKeys.length > 0 &&

                        <div className=' mt-3'>
                            <p className='font-semibold mb-2'>Select Key</p>
                            <select value={propertyValue} onChange={(e) => setPropertyValue(e.target.value)} name="" id="" className=' py-1 px-2 rounded focus:outline-none w-full border border-blue-500' >
                                <option value="">Select Key</option>
                                {
                                    companyKeys.map(key => {
                                        return (
                                            <option key={key._id} value={key.keyName}>{key.keyName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }
                    {
                        propertyType === "Array" && companyKeys.length > 0 &&

                        <div className=' mt-3'>
                            <p className='font-semibold mb-2'>Select Key</p>
                            <select value={propertyValue} onChange={(e) => setPropertyValue(e.target.value)} name="" id="" className='py-1 px-2 rounded focus:outline-none w-full border border-blue-500' >
                                <option value="">Select Key</option>
                                {
                                    companyKeys.map(key => {
                                        return (
                                            <option key={key._id} value={key.keyName}>{key.keyName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    }
                    {
                        companyKeys.length > 0 && propertyType === "Object" &&

                        <div className=' mt-3'>
                            <div>
                                <p className=' mb-3 font-semibold'>How many property do you want to add in the object?</p>
                                <div className=' grid grid-flow-col gap-3'>
                                    <input type="number" value={propartyQuantity} onChange={(e) => { setPropartyQuantity(e.target.value) }} className=' focus:outline-none py-1 px-2 rounded border border-blue-500' />
                                    <button onClick={showObjectPropartySlectionFunc} className='shadow shadow-purple-300 p-1 rounded text-purple-600 hover:scale-105 transition-all ease-in'>Add</button>
                                </div>
                            </div>

                            {
                                showObjectPropartySlection &&

                                <div>

                                    {
                                        randomArray.map(i => {
                                            return (
                                                <div key={i.index} className=" my-5 grid grid-cols-2 gap-3">
                                                    <div className=' w-full'>
                                                        <label htmlFor="" className=' block font-semibold mb-2'>Property name for object key</label>
                                                        <input type="text" value={i.name} onChange={(e) => changeProperty(e.target.value, i.index, "name")} placeholder='Enter property name' className="py-1 px-2 rounded focus:outline-none w-full border border-blue-500" />
                                                    </div>

                                                    <div className=' w-full justify-self-end'>
                                                        <p className='font-semibold mb-2'>Select key</p>
                                                        <select name="" id="" value={i.value} onChange={(e) => changeProperty(e.target.value, i.index, "value")} className=' focus:outline-none border border-blue-500 py-1 px-2 rounded' >
                                                            <option value="">Select Key</option>
                                                            {
                                                                companyKeys.map(key => {
                                                                    return (
                                                                        <option key={key._id} value={key.keyName}>{key.keyName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        companyKeys.length > 0 && propertyType === "ArrayObject" &&

                        <div className=' mt-3'>
                            <div>
                                <p className=' mb-5 font-semibold'>How many property do you want to add in the Array object?</p>
                                <div className=' grid grid-flow-col gap-3'>
                                    <input type="number" value={propartyQuantity} onChange={(e) => { setPropartyQuantity(e.target.value) }} className=' focus:outline-none py-1 px-2 rounded border border-blue-500' />
                                    <button onClick={showObjectPropartySlectionFunc} className=' shadow shadow-purple-300 p-1 rounded text-purple-600 hover:scale-105 transition-all ease-in '>Add</button>
                                </div>
                            </div>

                            {
                                showObjectPropartySlection &&

                                <div>

                                    {
                                        randomArray.map(i => {
                                            return (
                                                <div key={i.index} className="mt-5 grid grid-cols-2 gap-3">
                                                    <div >
                                                        <label htmlFor="" className=' block font-semibold mb-2'>Property name for object key</label>
                                                        <input value={i.name} onChange={(e) => changeProperty(e.target.value, i.index, "name")} type="text" placeholder='Enter property name' className="py-1 px-2 rounded focus:outline-none w-full border border-blue-500" />
                                                    </div>

                                                    <div>
                                                        <label htmlFor="" className=' block font-semibold mb-2'>Select key</label>
                                                        <select name="" value={i.value} onChange={(e) => changeProperty(e.target.value, i.index, "value")} id="" className=' focus:outline-none border border-blue-500 py-1 px-2 rounded' >
                                                            <option value="">Select Key</option>
                                                            {
                                                                companyKeys.map(key => {
                                                                    return (
                                                                        <option key={key._id} value={key.keyName}>{key.keyName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }

                    <div className=' w-full mt-10 flex justify-center'>
                        <button onClick={UpdateDataFormat} className='shadow shadow-blue-300 text-blue-600 px-2 py-1 rounded transition-all ease-in hover:scale-105'>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateDataFormat