
import axios from 'axios'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Host } from '../../Data'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UpdateDatasetCompnent({ toggleUpdateDatasetMode, updateAbleDataset, fatchdata }) {
    const companyUser = useSelector((state) => state.CompanyUser.CompanyUser)
    const [datasetName, setDatasetName] = useState(updateAbleDataset.name)
    const [datasetDescription, setDatasetDescription] = useState(updateAbleDataset.description)

    const CreateDataset = async (e) => {
        e.preventDefault()
        const data = {
            name: datasetName,
            description: datasetDescription
        }
        try {
            await axios.put(`${Host}/api/datasets/${updateAbleDataset._id}`, data, {
                headers: {
                    'Authorization': `Bearer ${companyUser.token}`
                }
            })
            fatchdata()
            toast.success("Dataset Updated")
        } catch (error) {
            console.log(error)
            toast.error("Something went wrong")
        }
    }

    return (
        <div className=' fixed top-0 left-0 z-50 w-full h-screen p-5 bg-black bg-opacity-25 flex justify-center'>
            <div className=' w-full sm:w-[600px]'>
                <div className=' w-full bg-white sm:mt-32 rounded shadow p-8'>
                    <p className='sm:text-lg font-medium text-center'>Update Dataset</p>
                    <div className=' flex justify-end'>
                        <button onClick={toggleUpdateDatasetMode} className='  shadow shadow-red-300 py-1 px-2 rounded text-red-700 -translate-y-5 transition-all ease-linear duration-200 hover:scale-105 font-medium'>Close</button>
                    </div>
                    <form className=' w-full' onSubmit={CreateDataset}>
                        <div className=' w-full'>
                            <label htmlFor="" className=' font-medium text-sm sm:text-base'>Name</label>
                            <input onChange={(e) => { setDatasetName(e.target.value) }} value={datasetName} type="text" placeholder='Name' required={true} className=' w-full p-1 sm:p-2 my-1 sm:my-2 border-[1px] border-gray-300 rounded focus:outline-none' />
                        </div>
                        <div className=' w-full'>
                            <label htmlFor="" className=' font-medium text-sm sm:text-base'>Description</label>
                            <input onChange={(e) => { setDatasetDescription(e.target.value) }} value={datasetDescription} type="text" placeholder="Description" required={true} className=' w-full p-1 sm:p-2 my-1 sm:my-2 border-[1px] border-gray-300 rounded focus:outline-none' />
                        </div>
                        <div className=' w-full flex justify-center mt-2 sm:mt-5'>
                            <button type='submit' className=' w-full shadow shadow-blue-300 p-2 rounded text-blue-700 -translate-y-5 transition-all ease-linear duration-200 hover:scale-105 sm:mt-2 mt-5 font-medium'>Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateDatasetCompnent