import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    Admin: localStorage.getItem('Admin') ? JSON.parse(localStorage.getItem('Admin')) : null,
    isLoading: false,
    error: "",
}

export const AdminSlice = createSlice({
    name: 'Admin',
    initialState,
    reducers: {
        loading: (state) => {
            state.isLoading = true;
        },
        adminLoginSuccess: (state, action) => {
            state.isLoading = false;
            state.Admin = action.payload;
            localStorage.setItem('Admin', JSON.stringify(action.payload));
        },
        adminUpdateSuccess: (state, action) => {
            state.isLoading = false;
            state.Admin = { token: state.Admin.token, ...action.payload }
            localStorage.setItem('Admin', JSON.stringify({ token: state.Admin.token, ...action.payload }));
        },
        loginFailure: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        adminLogout: (state) => {
            state.Admin = null;
            localStorage.removeItem('Admin');
            state.isLoading = false;
            state.error = '';
        }
    },
})

export const { loading, adminLoginSuccess, loginFailure, adminLogout, adminUpdateSuccess } = AdminSlice.actions

export default AdminSlice.reducer